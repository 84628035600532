import * as React from 'react';

import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

export interface FormSubListNewItemProps<T = Record<string, any>> {
  onAddClick: (value: T) => void;
  onClick?: () => void;
  validate?: (value: T) => boolean;
  className?: string;
}

export function FormSubListNewItem<T = Record<string, any>>({
  onAddClick,
  onClick,
  children,
  className,
  validate
}: React.PropsWithChildren<FormSubListNewItemProps<T>>) {
  const [newItem, setNewItem] = React.useState<T>({} as T);
  const [addBtnDisabled, setAddBtnDisabled] = React.useState(Boolean(validate));

  React.useEffect(() => {
    if (!validate) return;
    setAddBtnDisabled(!validate(newItem));
  }, [newItem, validate]);

  const handleAddClick = React.useCallback(() => {
    if (validate && !validate(newItem)) {
      setAddBtnDisabled(true);
      return;
    }

    onAddClick(newItem);
    setNewItem({} as T);
  }, [newItem, onAddClick, validate]);

  return React.isValidElement(children) ? (
    <div
      className={clsx('form-sub-list__new-item', className)}
      onClick={onClick}
    >
      {React.Children.only(
        React.cloneElement(children, { value: newItem, onChange: setNewItem })
      )}
      <IconButton
        disabled={addBtnDisabled}
        className="form-sub-list__btn-wrapper form-sub-list__btn"
        onClick={handleAddClick}
      >
        <AddIcon />
      </IconButton>
    </div>
  ) : null;
}
