import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Collection,
  QFormSection,
  QFormSectionItem,
  QFormSubcollection
} from 'documents';
import { PageState } from 'utils';
import { handleError, handlePending, PageError } from 'utils/handeling';

import { createRepo, Repo } from 'api/firebase';

export interface SectionsQuestionsState {
  qFormId: string;
  sections: QFormSection[];
  questions: QFormSectionItem[];
  activeSectionId?: string;
  sectionStatus: PageState;
  questionStatus: PageState;
}

interface ThunkApi {
  state: SectionsQuestionsState;
}

export const initialState: SectionsQuestionsState = {
  sectionStatus: {
    isLoading: true,
    hasError: false
  },
  questionStatus: {
    isLoading: true,
    hasError: false
  },
  sections: [],
  questions: [],
  qFormId: ''
};

const orderMultiplier = 10;

let sectionRepo: Repo<QFormSection>;
let questionRepo: Repo<QFormSectionItem>;

const handleSectionPending = ({ sectionStatus }: SectionsQuestionsState) =>
  handlePending(sectionStatus);
const handleSectionError = (
  { sectionStatus }: SectionsQuestionsState,
  error: PageError
) => handleError(sectionStatus, error);

const handleQuestionPending = ({ questionStatus }: SectionsQuestionsState) =>
  handlePending(questionStatus);
const handleQuestionError = (
  { questionStatus }: SectionsQuestionsState,
  error: PageError
) => handleError(questionStatus, error);

const loadSections = createAsyncThunk(
  'sectionsQuestions/sectionsQuestionsLoad',
  async (qFormId: string) => {
    sectionRepo = createRepo<QFormSection>(
      `${Collection.QForm}/${qFormId}/${QFormSubcollection.Sections}`
    );

    const sections = await sectionRepo.getList({ orderBy: ['order', 'asc'] });

    return { sections, qFormId };
  }
);

const loadQuestions = createAsyncThunk<
  QFormSectionItem[] | undefined,
  void,
  ThunkApi
>('sectionsQuestions/questionsLoad', async (_, { getState }) => {
  const { qFormId, activeSectionId: currentCatId } = getState();
  if (!currentCatId) return;

  questionRepo = createRepo<QFormSectionItem>([
    Collection.QForm,
    qFormId,
    QFormSubcollection.Sections,
    currentCatId,
    QFormSubcollection.SectionItems
  ]);

  return await questionRepo.getList({ orderBy: ['order', 'asc'] });
});

const addSection = createAsyncThunk<QFormSection, QFormSection, ThunkApi>(
  'sectionsQuestions/addSection',
  async (item: QFormSection, { getState }) => {
    const { sections } = getState();
    item.order = (sections.length + 1) * orderMultiplier;
    const id = await sectionRepo.add(item);

    return { ...item, id };
  }
);

const saveSection = createAsyncThunk(
  'sectionsQuestions/saveSection',
  (item: QFormSection) => sectionRepo.set(item)
);

const removeSection = createAsyncThunk(
  'sectionsQuestions/removeSection',
  async (item: QFormSection) => {
    if (!item.id) return;

    sectionRepo.delete(item.id);

    return item.id;
  }
);

const addQuestion = createAsyncThunk<
  QFormSectionItem,
  QFormSectionItem,
  ThunkApi
>(
  'sectionsQuestions/addQuestion',
  async (item: QFormSectionItem, { getState }) => {
    const { questions } = getState();
    item.order = (questions.length + 1) * 10;
    const id = await questionRepo.add(item);

    return { ...item, id };
  }
);

const saveQuestion = createAsyncThunk(
  'sectionsQuestions/saveQuestion',
  async (item: QFormSectionItem) => questionRepo.set(item)
);

const removeQuestion = createAsyncThunk(
  'sectionsQuestions/removeQuestion',
  async (item: QFormSectionItem) => {
    if (!item.id) return;

    questionRepo.delete(item.id);

    return item.id;
  }
);

const sectionsQuestionsSlice = createSlice({
  name: 'sectionsQuestions',
  initialState,
  reducers: {
    selectSection: (
      state,
      { payload: id }: PayloadAction<string | undefined>
    ) => {
      state.activeSectionId = id;
    },
    updateSection: (state, { payload: item }: PayloadAction<QFormSection>) => {
      const itemIndex = state.sections.findIndex(x => x.id === item.id);
      state.sections.splice(itemIndex, 1, item);
    },
    updateQuestion: (
      state,
      { payload: item }: PayloadAction<QFormSectionItem>
    ) => {
      const itemIndex = state.questions.findIndex(x => x.id === item.id);
      state.questions.splice(itemIndex, 1, item);
    }
  },
  extraReducers: builder => {
    builder.addCase(loadSections.pending, handleSectionPending);
    builder.addCase(loadSections.rejected, handleSectionError);
    builder.addCase(loadSections.fulfilled, (state, { payload }) => {
      const { sections, qFormId } = payload;
      state.sections = sections;
      state.qFormId = qFormId;
      state.activeSectionId = sections[0]?.id;
      state.sectionStatus.isLoading = false;
    });

    builder.addCase(loadQuestions.pending, handleQuestionPending);
    builder.addCase(loadQuestions.rejected, ({ questionStatus }, error) =>
      handleError(questionStatus, error)
    );
    builder.addCase(loadQuestions.fulfilled, (state, { payload }) => {
      state.questions = payload ?? [];
      state.questionStatus.isLoading = false;
    });

    // Section actions

    builder.addCase(addSection.pending, handleSectionPending);
    builder.addCase(addSection.rejected, handleSectionError);
    builder.addCase(addSection.fulfilled, (state, { payload: item }) => {
      if (item) {
        state.sections.push(item);
      }
      state.sectionStatus.isLoading = false;
    });

    builder.addCase(saveSection.rejected, handleSectionError);

    builder.addCase(removeSection.pending, handleSectionPending);
    builder.addCase(removeSection.rejected, handleSectionError);
    builder.addCase(removeSection.fulfilled, (state, { payload: id }) => {
      if (id) {
        state.sections = state.sections.filter(x => x.id !== id);
        state.activeSectionId = undefined;
        state.sectionStatus.isLoading = false;
      }
    });

    // Section item actions

    builder.addCase(addQuestion.pending, handleSectionPending);
    builder.addCase(addQuestion.rejected, handleSectionError);
    builder.addCase(addQuestion.fulfilled, (state, { payload: item }) => {
      if (item) {
        state.questions.push(item);
      }
      state.sectionStatus.isLoading = false;
    });

    builder.addCase(saveQuestion.rejected, handleQuestionError);

    builder.addCase(removeQuestion.pending, handleQuestionPending);
    builder.addCase(removeQuestion.rejected, handleQuestionError);
    builder.addCase(removeQuestion.fulfilled, (state, { payload: id }) => {
      if (id) {
        state.questions = state.questions.filter(x => x.id !== id);
        state.questionStatus.isLoading = false;
      }
    });
  }
});

export {
  addQuestion,
  addSection,
  loadQuestions,
  loadSections,
  removeQuestion,
  removeSection,
  saveQuestion,
  saveSection
};
export const {
  selectSection,
  updateSection,
  updateQuestion
} = sectionsQuestionsSlice.actions;
export default sectionsQuestionsSlice.reducer;
