import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';

import { variants } from './const';
import { useStyles } from './DeletionDialog.style';

export enum Quantifier {
  One = 0,
  Many = 1
}

export enum DeleteItemType {
  Section = 0,
  Question = 2,
  Questionnaire = 4,
  Answer = 6
}

export enum ButtonTypes {
  DeleteIcon = 0,
  ClearIcon = 1
}

interface DeleteDialogProps {
  onDelete: () => void;
  disabled?: boolean;
  type: DeleteItemType;
  quantifier: Quantifier;
  iconType?: ButtonTypes;
  className?: string;
  btnClassName?: string;
  tooltip: string;
}

export const DeletionDialog: React.FC<DeleteDialogProps> = ({
  onDelete,
  quantifier,
  disabled,
  type,
  iconType,
  className,
  btnClassName,
  tooltip
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = React.useState(false);

  const cancel = () => {
    setOpen(false);
  };

  const confirmDeletion = () => {
    setOpen(false);
    onDelete();
  };

  const handleOpenClick = () => {
    setOpen(true);
  };

  return (
    <div className={className}>
      <Tooltip title={tooltip}>
        <span>
          <IconButton
            className={btnClassName}
            onClick={handleOpenClick}
            disabled={disabled}
          >
            {iconType ? <ClearIcon /> : <DeleteIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        onBackdropClick={cancel}
        disableEscapeKeyDown={true}
        open={open}
        fullScreen={fullScreen}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>{t(variants[type + quantifier])}</DialogTitle>
        <DialogActions className={classes.actionButtons}>
          <Button onClick={() => cancel()} color="default">
            {t('Cancel')}
          </Button>
          <Button
            onClick={confirmDeletion}
            variant="outlined"
            color="secondary"
          >
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
