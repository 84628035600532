import * as React from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import {
  ButtonTypes,
  DeleteItemType,
  DeletionDialog,
  Quantifier
} from '../deletion-dialog';

export interface DeleteOptions {
  type: DeleteItemType;
  quantifier: Quantifier;
  iconType?: ButtonTypes;
}

export interface FormSubListItemProps<T> {
  value?: T;
  onChange?: (value: T) => void;
  onRemove?: (value: T) => void;
  disabled?: boolean;
  className?: string;
  deleteOptions: DeleteOptions;
  onClick?: () => void;
}

export function FormSubListItem<T = Record<string, any>>({
  value,
  onChange,
  onRemove,
  children,
  className,
  onClick,
  disabled,
  deleteOptions
}: React.PropsWithChildren<FormSubListItemProps<T>>) {
  const { t } = useTranslation();

  return React.isValidElement(children) ? (
    <div className={clsx('form-sub-list__item', className)} onClick={onClick}>
      {React.Children.only(
        React.cloneElement(children, {
          value: value,
          onChange: onChange,
          disabled
        })
      )}
      {!disabled && onRemove && (
        <DeletionDialog
          className="form-sub-list__btn-wrapper"
          btnClassName="form-sub-list__btn"
          onDelete={() => value && onRemove(value)}
          tooltip={t('Delete item')}
          {...deleteOptions}
        />
      )}
    </div>
  ) : null;
}
