import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField, TextFieldProps } from '@material-ui/core';

import { FormInputProps } from './types';

const FormInput: React.FC<FormInputProps & TextFieldProps> = React.memo(
  ({ label, required, name, rules, ...rest }) => {
    const { control } = useFormContext();
    const { t } = useTranslation();

    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: rules?.required || {
            value: required ?? false,
            message: t('This field is required')
          },
          pattern: rules?.pattern
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            error={invalid}
            helperText={error?.message}
            required={Boolean(rules?.required) || required}
            label={label}
            {...rest}
            {...field}
          />
        )}
      />
    );
  }
);

FormInput.displayName = 'FormInput';
export { FormInput };
