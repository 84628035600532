import * as React from 'react';

import clsx from 'clsx';
import { QFormSection } from 'documents';

import { DeleteItemType, Quantifier } from '../../deletion-dialog';
import {
  DeleteOptions,
  FormSubList,
  FormSubListItem,
  FormSubListNewItem
} from '../../form-sublist';
import { useStyles } from './Sections.styles';
import { SectionItem } from './SectionItem';

export interface SectionsProps {
  items: QFormSection[];
  activeItemId?: string;
  onSelect: (itemId?: string) => void;
  onChange: (item: QFormSection) => void;
  onRemove: (item: QFormSection) => void;
}

export const Sections: React.FC<SectionsProps> = ({
  items,
  activeItemId,
  onSelect,
  onChange,
  onRemove
}: SectionsProps) => {
  const classes = useStyles();

  const handleNewItemClick = React.useCallback(() => {
    onSelect(undefined);
  }, [onSelect]);

  return (
    <FormSubList id={'field'}>
      {items.map(item => (
        <FormSubListItem
          key={item.id!}
          onClick={() => onSelect(item.id!)}
          onRemove={onRemove}
          onChange={onChange}
          value={item}
          className={clsx(
            classes.listItem,
            activeItemId === item.id && classes.activeListItem
          )}
          deleteOptions={deleteOptions}
        >
          <SectionItem editOnClick active={activeItemId === item.id} />
        </FormSubListItem>
      ))}

      <FormSubListNewItem
        className={clsx(
          classes.listItem,
          !activeItemId && classes.activeListItem
        )}
        validate={newItemValidator}
        onClick={handleNewItemClick}
        onAddClick={onChange}
      >
        <SectionItem active={!activeItemId} />
      </FormSubListNewItem>
    </FormSubList>
  );
};

const deleteOptions: DeleteOptions = {
  type: DeleteItemType.Section,
  quantifier: Quantifier.One
};

const newItemValidator = (x: QFormSection) => Boolean(x.title);
