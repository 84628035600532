import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup
} from '@material-ui/core';

export interface RadioGroupOption {
  label: string;
  value: string;
}

export interface RadioGroupProps {
  name?: string;
  label?: string;
  value?: string;
  options: RadioGroupOption[];
  onChange?: (value: string) => void;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  className?: string;
  formClassName?: string;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  label,
  value,
  options,
  onChange,
  error,
  errorMessage,
  required,
  className,
  formClassName
}) => {
  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    onChange?.(value);
  };

  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      className={formClassName}
    >
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <MuiRadioGroup
        aria-label="gender"
        name={name}
        value={value}
        defaultValue={value || options[0].value} // ???
        onChange={handleChange}
        className={className}
      >
        {options.map((x, i) => (
          <FormControlLabel
            key={i}
            value={x.value}
            control={<Radio />}
            label={x.label}
          />
        ))}
      </MuiRadioGroup>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};
