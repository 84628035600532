import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme
} from '@material-ui/core';

import { Filter } from './Filter.types';

export interface SingleFilterControlProps {
  id: string;
  value: string;
  filter: Filter;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const useSingleFilterControlStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      width: '100%'
    }
  })
);

export const SingleFilterControl: React.FC<SingleFilterControlProps> = ({
  id,
  value,
  filter,
  onChange
}) => {
  const { t } = useTranslation();
  const classes = useSingleFilterControlStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={id}>{filter.label}</InputLabel>
      <Select
        labelId={id}
        onChange={onChange}
        value={value}
        id={`select_${id}`}
      >
        <MenuItem value="">{t('None')}</MenuItem>
        {filter.keyValues?.map(keyValue => (
          <MenuItem key={keyValue.displayKey} value={keyValue.filterValue + ''}>
            {t(keyValue.displayKey)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
