import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useAppLogoStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      color: theme.palette.secondary.main,
      '&:hover, &:active': { textDecoration: 'none' },
      '& > *': {
        verticalAlign: 'middle'
      },
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '31px'
      }
    },
    logo: {
      [theme.breakpoints.down('sm')]: {
        width: '43px',
        marginRight: '6px'
      },
      [theme.breakpoints.up('md')]: {
        width: '65px',
        marginRight: '13px'
      },
      height: '41px'
    },
    readyAstraLogo: {
      [theme.breakpoints.down('sm')]: {
        height: '41px'
      },
      [theme.breakpoints.up('md')]: {
        height: '61px'
      }
    },
    name: {
      color: theme.palette.text.primary
    },
    regMark: {
      fontSize: '5px',
      verticalAlign: 'top',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '15px'
      },
      [theme.breakpoints.up('md')]: {
        lineHeight: '31px'
      }
    }
  })
);
