import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import { dateTimeFormat } from 'constants/date-time';
import { get, isNil, orderBy as lodashOrderBy, toString } from 'lodash';
import { dateFormatter } from 'utils/date-formatter';

import {
  EnhancedColumnOrder,
  EnhancedHeaderCell,
  EnhancedTableProp,
  EnhancedTableToolbar
} from './';
import { useStyles } from './EnhancedTable.styles';
import EnhancedTableHeader from './EnhancedTableHeader';
import EnhancedTablePaginator from './EnhancedTablePaginator';
import { FilterValue } from './filter/Filter.types';
import { NoDataBody } from './NoDataBody';

function mapTableCell<T>(
  row: T,
  column: EnhancedHeaderCell,
  lng: string,
  onClick: (r: T) => void,
  className: string
) {
  const value = get(row, column.id);
  let formatedValue = value;

  if (isNil(formatedValue)) {
    return <TableCell key={column.id} onClick={() => onClick(row)} />;
  }

  switch (column.type) {
    case 'Date':
      formatedValue = dateFormatter(value, lng);
      break;
    case 'DateTime':
      formatedValue = dateFormatter(value, lng, dateTimeFormat);
      break;
    default:
      formatedValue = column.format ? column.format(value) : value;
  }

  return (
    <TableCell
      className={className}
      key={column.id}
      align={column.align || 'left'}
      onClick={() => onClick(row)}
    >
      {formatedValue}
    </TableCell>
  );
}

export const EnhancedTable = <T,>({
  tableProps: {
    defaultOrder,
    defaultOrderBy,
    rows,
    highlightedRowIds,
    italicRowIds,
    initialPerPage,
    onSelected,
    onRowClick,
    tableName,
    initialRowsCount,
    filters,
    initialFiltersValue,
    headerCells,
    checkboxSelection,
    perPageList,
    selected
  },
  toolbar
}: EnhancedTableProp<T>) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = useState<EnhancedColumnOrder>(defaultOrder);
  const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
  const [displayedRows, setDisplayedRows] = useState(rows);
  const [filtersValue, setFiltersValue] = useState<FilterValue[]>(
    initialFiltersValue ?? []
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialPerPage);
  const [rowsCount, setRowsCount] = useState(initialRowsCount);

  const highlightedClassName = (row: T) =>
    highlightedRowIds?.includes(get(row, 'id')) ? classes.bold : '';

  const italicClassName = (row: T) =>
    italicRowIds?.includes(get(row, 'id')) ? classes.italic : '';

  const renderTableRow = (row: T) => (
    <TableRow
      className={`${highlightedClassName(row)} ${italicClassName(row)}`}
      hover
      tabIndex={-1}
      key={get(row, 'id')}
    >
      {checkboxSelection && (
        <TableCell
          className={classes.hover}
          padding="checkbox"
          onClick={() => handleSelectClick(row)}
        >
          <Checkbox checked={isSelected(row)} />
        </TableCell>
      )}
      {headerCells
        .filter(column => !column.breakpointDown && !column.breakpointUp)
        .map(column =>
          mapTableCell(
            row,
            column,
            i18n.language,
            handleRowClick,
            classes.hover
          )
        )}
    </TableRow>
  );

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSetPerPageChange = (perPage: number) => {
    setRowsPerPage(perPage);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsToSelect = event.target.checked ? displayedRows : [];
    onSelected && onSelected(rowsToSelect);
  };

  const handleRowClick = (row: T) => {
    onRowClick && onRowClick(row);
  };

  const handleSelectClick = (row: T) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected =
      selectedIndex === -1
        ? [...selected, row]
        : [
            ...selected.slice(0, selectedIndex),
            ...selected.slice(selectedIndex + 1)
          ];

    onSelected && onSelected(newSelected);
  };

  const filterRows = (nonFilteredRows: T[], fv: FilterValue[]): T[] =>
    nonFilteredRows.filter(
      row =>
        !fv.filter(f => !!f.values).length ||
        fv
          .filter(f => !!f.values)
          .every(f => {
            switch (f.type) {
              case 'Text':
                return toString(get(row, f.id))
                  .toLowerCase()
                  .includes(f.values[0].toString().toLowerCase());
              case 'Single':
                const filterValueDefinition = filters
                  ?.find(x => x.id === f.id)
                  ?.keyValues?.find(kv => kv.filterValue === f.values[0]);
                if (filterValueDefinition?.matchFunc) {
                  return filterValueDefinition.matchFunc(row);
                } else {
                  return f.values.includes(toString(get(row, f.id)));
                }
              default:
                return f.values.includes(toString(get(row, f.id)));
            }
          })
    );

  const handleFilterChange = (fv: FilterValue[]) => {
    setFiltersValue(fv);
  };

  const isSelected = (row: T) => selected.indexOf(row) !== -1;

  useEffect(() => {
    const filtered = filterRows(rows, filtersValue);
    setDisplayedRows(filtered);
    setRowsCount(filtered.length);
    if (page * rowsPerPage > filtered.length) {
      setPage(Math.floor(filtered.length / rowsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersValue, rows]);

  const emptyRows = () =>
    rowsPerPage - Math.min(rowsPerPage, rowsCount - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          tableName={tableName}
          filters={filters}
          initialFiltersValue={initialFiltersValue}
          extra={toolbar?.extra}
          onFilterChange={handleFilterChange}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHeader
              classes={classes}
              order={order}
              orderBy={orderBy}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              rowsCount={rowsCount}
              headerCells={headerCells}
              checkboxSelection={checkboxSelection}
              onSelectAllClick={handleSelectAllClick}
              rows={displayedRows}
            />
            {displayedRows?.length ? (
              <TableBody>
                {lodashOrderBy<T>(displayedRows, [orderBy], order)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => renderTableRow(row))}
                {emptyRows() > 0 && (
                  <TableRow style={{ height: 50 * emptyRows() }}>
                    <TableCell
                      colSpan={headerCells.length + Number(!!checkboxSelection)}
                    />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <NoDataBody
                height={50 * rowsPerPage}
                colSpan={headerCells.length + Number(!!checkboxSelection)}
              />
            )}
          </Table>
        </TableContainer>
        <EnhancedTablePaginator
          page={page}
          perPage={rowsPerPage}
          rowsCount={rowsCount}
          perPageList={perPageList}
          initialPerPage={initialPerPage}
          onChangePage={handlePageChange}
          onChangeRowPerPage={handleSetPerPageChange}
        />
      </Paper>
    </div>
  );
};
