import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';

export interface VariantType {
  name: string;
  index?: number;
}

export interface VariantItemProps {
  value?: VariantType;
  onChange?: (value?: any) => void;
}

export const VariantItem: React.FC<VariantItemProps> = ({
  value,
  onChange
}: VariantItemProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      value={value?.name ?? ''}
      placeholder={t('Answer option')}
      onChange={e => onChange?.({ ...value, name: e.target.value })}
    />
  );
};
