import { initReactI18next } from 'react-i18next';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { getRussianOnlyAssistantTypes } from 'constants/assistant-type';

/**
 * Type for an i18n field is a firestore document.
 * The field is an object which key is locale. Example:
 *    doc {
 *      name: {
 *        en: 'name',
 *        ru: 'имя',
 *        es: 'nombre'
 *      }
 *    }
 */
export type I18nField = Record<string, string>;

export const languages = ['en', 'ru'];

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    lng: getRussianOnlyAssistantTypes().includes(
      process.env.REACT_APP_ASSISTANT_TYPE!
    )
      ? 'ru'
      : undefined,
    fallbackLng: false,
    nsSeparator: false,
    keySeparator: false,
    supportedLngs: languages,
    nonExplicitSupportedLngs: false,
    load: 'languageOnly',
    interpolation: { escapeValue: false }
  })
  .then(() => {
    i18next.changeLanguage(i18next.languages[0]);
  });

export const translate = (docField: I18nField) =>
  docField[i18next.language] ? docField[i18next.language] : docField['en'];

export default i18next;
