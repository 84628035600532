import * as React from 'react';

import { AssistantType } from 'constants/assistant-type';
import i18n from 'services/i18n';
import { loadScript } from 'utils/load-script';

import { t } from '../utils';

declare global {
  interface Window {
    jivo_destroy: () => void;
  }
}

const jivoChatUrls: Record<string, string> = {
  [AssistantType.Fin]: 'code-ya.jivosite.com/widget/1KE6ypoUdG',
  [AssistantType.It]: t('code-ya.jivosite.com/widget/xYdIHCwATI'),
  [AssistantType.ReadyAstra]: t('code-ya.jivosite.com/widget/Oy6NwMurOQ'),
  [AssistantType.Med]: t('code-ya.jivosite.com/widget/xYdIHCwATI')
};

function loadJivoScript(lang: string) {
  loadScript(
    `https://${i18n.getFixedT(lang)(
      jivoChatUrls[process.env.REACT_APP_ASSISTANT_TYPE!]
    )}`,
    'jivosite'
  );
}

async function onLangChanged(lang: string) {
  try {
    if (window.jivo_destroy) window.jivo_destroy();
  } catch {}

  loadJivoScript(lang);
}

export function useJivo() {
  React.useEffect(() => {
    if (process.env.REACT_APP_USE_FIREBASE_EMULATOR) return;
    if (!process.env.REACT_APP_ASSISTANT_TYPE) {
      console.error('ASSISTANT_TYPE undefined, cannot run JivoChat');
      return;
    }

    setTimeout(() => loadJivoScript(i18n.language), 1000);

    i18n.on('languageChanged', onLangChanged);
    return () => {
      i18n.off('languageChanged', onLangChanged);
    };
  }, []);
}
