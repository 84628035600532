import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

export interface FilterButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FilterButton: React.FC<FilterButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('Filter') || ''}>
      <IconButton aria-label="filter list" onClick={onClick}>
        <FilterListIcon />
      </IconButton>
    </Tooltip>
  );
};
