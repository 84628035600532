import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { AssistantType } from 'constants/assistant-type';

interface Props {
  force?: boolean;
}

export const ConditionalDivider: React.FC<Props> = ({ force }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const showDividerAlways =
    process.env.REACT_APP_ASSISTANT_TYPE === AssistantType.Med;
  return force || sm || showDividerAlways ? <Divider /> : null;
};
