import * as React from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEqual, omitBy } from 'lodash';

import { LoadingButton } from 'components';

export interface FormProps<TFieldValues extends FieldValues> {
  defaultValues?: TFieldValues;
  values?: TFieldValues;
  onSubmit?: (value: TFieldValues) => void | Promise<void>;
  onDelete?: (id: string) => void | Promise<void>;
  onCancel?: () => void;
  onChange?: (value: TFieldValues) => void;
  className?: string;
  loading?: boolean;
  submitColor?: 'inherit' | 'primary' | 'secondary' | 'default';
}

export function Form<T extends { [x: string]: any }>({
  defaultValues,
  values,
  onSubmit,
  onDelete,
  onCancel,
  onChange,
  className,
  loading,
  submitColor = 'secondary',
  children
}: React.PropsWithChildren<FormProps<T>>) {
  const formMethods = useForm({
    defaultValues: { ...defaultValues }
  });

  const defaultValuesRef = React.useRef(defaultValues);

  const { reset, formState, watch } = formMethods;

  const { isDirty, isValid } = formState;

  const { t } = useTranslation();

  const handleDelete = React.useCallback(
    () =>
      window.confirm(t('Are you sure you want to delete this item?')) &&
      values &&
      onDelete?.(values?.id),
    [onDelete, t, values]
  );

  React.useEffect(() => {
    reset(
      values && Object.keys(values).length ? values : defaultValuesRef.current
    );
  }, [reset, values]);

  React.useEffect(() => {
    if (!onChange) return;

    const subscription = watch(value => {
      const clearedValue = omitBy(
        value,
        (v: any) => v === '' || (Array.isArray(v) && !v.length)
      ) as T;

      if (!isEqual(clearedValue, values)) {
        onChange(clearedValue);
      }
    });
    return () => subscription.unsubscribe();
  }, [onChange, values, watch]);

  const isAddNewMode = Boolean(onCancel);

  return (
    <FormProvider {...formMethods}>
      <form
        className={className}
        onSubmit={
          onSubmit &&
          formMethods.handleSubmit(onSubmit as SubmitHandler<FieldValues>)
        }
        noValidate
      >
        {children}
        <section className="form-footer">
          {onSubmit && (
            <LoadingButton
              disabled={!isDirty || !isValid}
              variant="contained"
              color={submitColor}
              type="submit"
              loading={loading}
            >
              {isAddNewMode ? t('Create') : t('Save')}
            </LoadingButton>
          )}
          {onDelete && (
            <LoadingButton
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
              loading={loading}
            >
              {t('Delete')}
            </LoadingButton>
          )}
          {onCancel && (
            <Button variant="outlined" onClick={onCancel}>
              {t('Cancel')}
            </Button>
          )}
        </section>
      </form>
    </FormProvider>
  );
}
