import { FC, ReactNode, memo } from 'react';

interface FormItemProps {
  label: string;
  className?: string;
  children?: ReactNode;
}

const FormItem: FC<FormItemProps> = memo(({
  label,
  className,
  children
}) => {
  return (
    <div className={className}>
      <label>{label}</label>
      {children}
    </div>
  );
});

FormItem.displayName = 'FormItem';
export { FormItem };
