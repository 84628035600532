import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    display: 'inline-flex'
  },
  btn: {
    flex: 1
  },
  progress: {
    color: theme.palette.secondary.main,
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%'
  }
}));
