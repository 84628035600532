import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: theme.spacing(2)
  },
  listItem: {
    position: 'relative',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:nth-child(odd)': {
      background: 'rgba(0, 0, 0, 0.02)'
    }
  },
  activeListItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  activeMarker: {
    width: theme.spacing(1),
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    background: theme.palette.secondary.main
  },
  sectionItemInput: {
    width: '100%'
  }
}));
