import './DecisionMatrixViewer.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@material-ui/lab';
import {
  DecisionMatrix,
  DecisionMatrixLeaf,
  DecisionMatrixNode,
  MatrixType
} from 'documents';

import { BusinessTasksLeafInfo } from './business-tasks-leaf-info';
import { ButtonGrid } from './button-grid';
import { ClaimsLeafInfo } from './ClaimsLeafInfo';
import { Intro } from './intro';
import { denormalize, getRelatedBusinessTasks } from './normalizer';
import { Tree } from './Tree';

export interface DecisionMatrixProps {
  matrices: Record<string, DecisionMatrix>;
  mxNodes: Record<string, Record<string, DecisionMatrixNode>>;
  mxLeaves: Record<string, Record<string, DecisionMatrixLeaf>>;
  defaultMx?: MatrixType;
  selectedMx?: MatrixType;
  selectedNodes?: string[];
  selectedLeaf?: string;
  forceLevelDivider?: boolean;
  onMxSelect?: (mx: MatrixType) => void;
  onNodeSelect?: (id: string, treeLvl: number) => void;
  onLeafSelect?: (id: string) => void;
}

export const DecisionMatrixViewer: React.FC<DecisionMatrixProps> = ({
  matrices,
  mxNodes,
  mxLeaves,
  defaultMx,
  selectedMx: outSelectedMx,
  forceLevelDivider,
  onMxSelect,
  ...props
}) => {
  const [selectedMx, setSelectedMx] = React.useState<MatrixType | undefined>(
    defaultMx || outSelectedMx
  );
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!outSelectedMx) return;
    setSelectedMx(outSelectedMx);
  }, [outSelectedMx]);

  const handleMxSelect = React.useCallback(
    (id: string) => {
      const mx = id as MatrixType;
      setSelectedMx(mx);
      onMxSelect?.(mx);
    },
    [onMxSelect]
  );

  const showHtmlDesc = matrices[MatrixType.Claims]?.showHtmlDesc ?? false;

  return !Object.keys(matrices).length ? (
    <Alert severity="info">{t('There is no data here')}.</Alert>
  ) : (
    <div className="decision-matrix">
      <Intro />
      <ButtonGrid
        className="decision-matrix__names"
        items={denormalize(matrices)}
        selected={selectedMx}
        onSelect={handleMxSelect}
      />
      <div className="decision-matrix__content">
        {selectedMx && (
          <Tree
            matrixType={selectedMx}
            nodes={mxNodes[selectedMx]}
            leaves={mxLeaves[selectedMx]}
            forceLevelDivider={forceLevelDivider}
            renderLeafInfo={selectedLeafId =>
              selectedMx === MatrixType.Claims ? (
                <ClaimsLeafInfo
                  title={matrices[MatrixType.BusinessTasks].name}
                  tasks={getRelatedBusinessTasks(
                    mxLeaves[MatrixType.BusinessTasks],
                    selectedLeafId
                  )}
                  leaf={mxLeaves[selectedMx][selectedLeafId]}
                  showHtmlDesc={showHtmlDesc}
                  showBusinessTasksRelations={
                    matrices[selectedMx].showBusinessTasksRelations
                  }
                />
              ) : (
                <BusinessTasksLeafInfo
                  claimMxElems={
                    mxLeaves[selectedMx][selectedLeafId]?.claimsMatrixLeaves ??
                    []
                  }
                  claimMxNodes={mxNodes[MatrixType.Claims]}
                  claimMxLeaves={mxLeaves[MatrixType.Claims]}
                  showHtmlDesc={showHtmlDesc}
                  leafId={mxLeaves[selectedMx][selectedLeafId].id!}
                />
              )
            }
            {...props}
          />
        )}
      </div>
    </div>
  );
};
