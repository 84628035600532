import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';
import { QFormAnswerType, QFormAnswerTypes, QFormSectionItem } from 'documents';

import { ButtonTypes, DeleteItemType, Quantifier } from '../../deletion-dialog';
import {
  DeleteOptions,
  FormSubList,
  FormSubListItem,
  FormSubListNewItem
} from '../../form-sublist';
import { RadioGroup, RadioGroupOption } from '../../radio-group';
import { useStyles } from './QuestionItem.styles';
import { VariantItem, VariantType } from './VariantItem';

export interface QuestionItemProps {
  value?: QFormSectionItem;
  onChange?: (value: QFormSectionItem) => void;
  active?: boolean;
  editOnClick?: boolean;
}

export const QuestionItem: React.FC<QuestionItemProps> = ({
  value,
  onChange,
  active,
  editOnClick
}: QuestionItemProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const typesLabels = [
    t('short text'),
    t('long text'),
    t('select one'),
    t('select many')
  ];
  const options = QFormAnswerTypes?.map(
    (el, ind) => ({ label: typesLabels[ind], value: el } as RadioGroupOption)
  );

  const handleQuestionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => onChange?.({ ...value, question: e.target.value });

  const handleAnswerTypeChange = (answerType: string) =>
    onChange?.({ ...value, answerType: answerType as QFormAnswerType });

  const handleAnswerOptionChange = (option: VariantType) => {
    const { name, index } = option;

    const answerOptions = [...(value?.answerOptions ?? [])];
    if (index != null) {
      answerOptions.splice(index, 1, name);
    } else {
      answerOptions.push(name);
    }

    onChange?.({ ...value, answerOptions });
  };

  const handleAnswerOptionRemove = ({ index }: VariantType) => {
    if (index == null) return;

    const answerOptions = [...(value?.answerOptions ?? [])];
    answerOptions.splice(index, 1);

    onChange?.({
      ...value,
      answerOptions
    });
  };

  const showAnswerOptionsBlock =
    value?.answerType === 'radio' || value?.answerType === 'checkbox';

  const validateNewVariantItem = React.useCallback(
    (item: VariantType) => Boolean(item.name),
    []
  );

  return (
    <div className={classes.container}>
      {active && <div className={classes.activeMarker} />}
      <div className={classes.content}>
        <TextField
          value={value?.question ?? ''}
          placeholder={t('Text of question')}
          onChange={handleQuestionChange}
          InputProps={{ disableUnderline: editOnClick && !active }}
        />
        <RadioGroup
          options={options}
          className={classes.radioButton}
          value={value?.answerType ?? ''}
          onChange={handleAnswerTypeChange}
        />
        {showAnswerOptionsBlock && (
          <FormSubList className={classes.answerOptions}>
            {value.answerOptions?.map((name, index) => (
              <FormSubListItem
                key={name}
                value={{ name, index }}
                onRemove={handleAnswerOptionRemove}
                onChange={handleAnswerOptionChange}
                deleteOptions={deleteOptions}
              >
                <VariantItem />
              </FormSubListItem>
            ))}
            <FormSubListNewItem
              validate={validateNewVariantItem}
              onAddClick={handleAnswerOptionChange}
            >
              <VariantItem />
            </FormSubListNewItem>
          </FormSubList>
        )}
      </div>
    </div>
  );
};

const deleteOptions: DeleteOptions = {
  type: DeleteItemType.Answer,
  quantifier: Quantifier.One,
  iconType: ButtonTypes.ClearIcon
};
