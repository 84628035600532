import * as React from 'react';

import { DecisionMatrixLeaf, DecisionMatrixNode, MatrixType } from 'documents';
import { last } from 'lodash/fp';

import { ConditionalDivider } from '../ConditionalDivider';
import { ButtonGrid } from './button-grid';
import { denormalize, denormalizeLeaves } from './normalizer';
import { buildTree, hasIntersection } from './utils';

export interface TreeProps {
  matrixType: MatrixType;
  nodes: Record<string, DecisionMatrixNode>;
  leaves: Record<string, DecisionMatrixLeaf>;
  rootId?: string;
  renderLeafInfo?: (selectedLeafId: string) => JSX.Element;
  selectedNodes?: string[];
  selectedLeaf?: string;
  onNodeSelect?: (id: string, treeLvl: number) => void;
  onLeafSelect?: (id: string) => void;
  forceLevelDivider?: boolean;
}

export const Tree: React.FC<TreeProps> = ({
  matrixType,
  nodes,
  leaves,
  rootId,
  renderLeafInfo,
  selectedNodes,
  selectedLeaf,
  onNodeSelect,
  onLeafSelect,
  forceLevelDivider
}) => {
  const [selectedNodeIds, setSelectedNodeIds] = React.useState(
    selectedNodes ?? []
  );
  const [selectedLeafId, setSelectedLeafId] = React.useState(
    selectedLeaf ?? ''
  );
  const [roots, setRoots] = React.useState<DecisionMatrixNode[]>([]);

  React.useEffect(() => {
    const dNodes: DecisionMatrixNode[] = denormalize(nodes);

    const newRoots = rootId
      ? dNodes.filter(x => x.parent && rootId === x.parent)
      : dNodes.filter(x => !x.parent);

    setRoots(newRoots);
    if (rootId) {
      setSelectedNodeIds([rootId]);
    }
  }, [nodes, leaves, rootId]);

  React.useEffect(() => {
    if (!selectedNodes) return;
    setSelectedNodeIds(selectedNodes);
  }, [selectedNodes]);

  React.useEffect(() => {
    if (selectedLeaf == null) return;
    setSelectedLeafId(selectedLeaf);
  }, [selectedLeaf]);

  const isNodeActive = React.useCallback(
    (nodeId: string) =>
      Object.values(nodes).some(x => x.parent === nodeId) ||
      Object.values(leaves).some(x => x.node === nodeId),
    [nodes, leaves]
  );

  const hasSelectedNodeOnLevel = React.useCallback(
    (items: DecisionMatrixNode[]) =>
      hasIntersection(
        items.map(x => x.id ?? ''),
        selectedNodeIds
      ),
    [selectedNodeIds]
  );

  const selectLeaf = React.useCallback(
    (id: string) => {
      setSelectedLeafId(id);
      onLeafSelect?.(id);
    },
    [onLeafSelect]
  );

  return (
    <>
      {buildTree(roots, selectedNodeIds, denormalize(nodes)).map(
        (items, lvl) => (
          <React.Fragment key={lvl}>
            <ButtonGrid
              className="decision-matrix__nodes"
              items={items.map(x => ({
                ...x,
                active: isNodeActive(x.id ?? '')
              }))}
              selected={selectedNodeIds?.[lvl]}
              onSelect={nodeId => {
                setSelectedNodeIds(prev => [...prev.slice(0, lvl), nodeId]);
                setSelectedLeafId('');
                onNodeSelect?.(nodeId, lvl);
              }}
            />
            {hasSelectedNodeOnLevel(items) &&
              isNodeActive(selectedNodeIds[lvl]) && (
                <ConditionalDivider force={forceLevelDivider} />
              )}
          </React.Fragment>
        )
      )}

      {!!selectedNodeIds.length && (
        <ButtonGrid // leaves of claims or tasks matrix
          className={`decision-matrix__leaves ${
            matrixType === MatrixType.BusinessTasks
              ? 'decision-matrix__centered'
              : ''
          }`}
          items={denormalizeLeaves(leaves, last(selectedNodeIds)!)}
          selected={selectedLeafId}
          onSelect={selectLeaf}
        />
      )}

      {selectedLeafId &&
        leaves[selectedLeafId] &&
        renderLeafInfo &&
        renderLeafInfo(selectedLeafId)}
    </>
  );
};
