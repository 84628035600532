import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 0, 1, 2)
  },
  activeMarker: {
    width: theme.spacing(1),
    height: '100%',
    position: 'absolute',
    left: 0,
    background: theme.palette.secondary.main
  },
  radioButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    flexWrap: 'wrap'
  },
  answerOptions: {
    '& .form-sub-list__list': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      flexWrap: 'wrap'
    }
  }
}));
