import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase'
  },
  leaves: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& .MuiButton-root': {
      borderRadius: '8px'
    } //,
    //justifyContent: 'center'
  },
  nodes: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'center',
    '& button': {
      borderRadius: '8px',
      fontSize: '1rem'
    }
  }
}));
