import React, { useState } from 'react';

import {
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme
} from '@material-ui/core';
import { entries, get, set } from 'lodash';

import { FilterButton, SingleFilterControl, TextFilterControl } from './';
import { Filter, FilterValue } from './Filter.types';

export interface FilterProps {
  filters: Filter[];
  initialFiltersValue?: FilterValue[];
  onFilterChange: (filtersValue: FilterValue[]) => void;
}

const useTableFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlContainer: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  })
);

export const TableFilter: React.FC<FilterProps> = ({
  filters,
  initialFiltersValue,
  onFilterChange
}) => {
  const classes = useTableFilterStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const initFiltersValue = filters
    ? filters.reduce((obj, filter) => {
        const values = initialFiltersValue
          ? initialFiltersValue.find(v => v.id === filter['id'])?.values
          : null;
        const value = values ? values[0] : '';
        return {
          ...obj,
          [filter['id']]: value
        };
      }, {})
    : [];

  const [filtersValue, setFiltersValue] = useState(initFiltersValue);

  const handleChange = (filter: Filter) => ({
    target: { value }
  }: React.ChangeEvent<{ value: unknown }>) => {
    set(filtersValue, filter.id, value);
    setFiltersValue(filtersValue);

    const res: FilterValue[] = entries(filtersValue).map(([key, value]) => ({
      id: key,
      values: value ? [value] : null,
      type: filters.find(y => y.id === key)?.type!
    }));

    onFilterChange(res);
  };

  return (
    <>
      <FilterButton onClick={e => setAnchorEl(e.currentTarget)} />
      <Menu
        className={classes.controlContainer}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {filters
          .filter(filter => !filter.breakpointDown && !filter.breakpointUp)
          .map(filter => {
            const id = filter.id;

            return (
              <MenuItem key={id}>
                {filter.type === 'Single' && (
                  <SingleFilterControl
                    id={id}
                    filter={filter}
                    onChange={handleChange(filter)}
                    value={get(filtersValue, id)}
                  />
                )}
                {filter.type === 'Text' && (
                  <TextFilterControl
                    id={id}
                    filter={filter}
                    onChange={handleChange(filter)}
                  />
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};
