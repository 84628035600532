import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  btn: {
    textTransform: 'none',
    backgroundColor: '#fff',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.7,
      color: '#fff'
    }
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff'
  },
  inactive: {
    opacity: 0.5
  }
}));
