import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import authReducer from 'features/auth/authSlice';

import appReducer from './appSlice';

const createRootReducer = (history: any) =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    router: connectRouter(history)
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
