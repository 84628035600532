import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { Collection } from 'documents';
import { UserProfile } from 'documents/userProfile';
import firebase from 'firebase/app';
import { handleError, handlePending } from 'utils/handeling';

import {
  addCustomerRole,
  db,
  getDataConverter,
  sendEmailVerification
} from 'api/firebase';

import { RootState } from 'app/rootReducer';
import { AppThunk } from 'app/store';
import { RouteName } from '../../route';

export enum Role {
  Admin = 'Admin',
  Customer = 'Customer',
  Vendor = 'Vendor'
}

export interface User {
  displayName: string | null;
  email: string | null;
  uid: string;
  role: Role;
  emailVerified: boolean;
}

export interface AuthState {
  isLoading: boolean;
  user: User | null;
  hasError: boolean;
}

const initialState: AuthState = {
  isLoading: true,
  user: null,
  hasError: false
};

interface ThunkApi<TState> {
  state: TState;
}

const restoreAuth = createAsyncThunk<
  void,
  firebase.auth.Auth,
  ThunkApi<RootState>
>('authSlice/restoreAuth', (auth, { dispatch, getState }) => {
  const onIdTokenChanged = async (firebaseUser: firebase.User | null) => {
    if (firebaseUser === null) {
      dispatch(authSlice.actions.stopLoading());
      return;
    }

    const token = await firebaseUser.getIdTokenResult();

    if (!token.claims.role) return;

    const user: User = {
      displayName: firebaseUser.displayName,
      email: firebaseUser.email,
      uid: firebaseUser.uid,
      role: token.claims.role,
      emailVerified: firebaseUser.emailVerified
    };

    dispatch(authSlice.actions.signIn(user));

    dispatchReturnUrl(getState, dispatch);
  };

  auth.onIdTokenChanged(onIdTokenChanged);
  auth.onAuthStateChanged(user => {
    if (!user) {
      dispatch(authSlice.actions.stopLoading());
    }
  });
});

const signUp = createAsyncThunk<
  void,
  { auth: firebase.auth.Auth; language: string },
  ThunkApi<AuthState>
>('authSlice/signUp', async ({ auth, language }, { dispatch, getState }) => {
  dispatch(
    sendEmailConfirmation({ auth, language: language, useRedirectPath: true })
  );
  await addCustomerRole();
  await auth.currentUser?.getIdToken(true);

  await db
    .collection(Collection.UserProfiles)
    .doc(auth.currentUser?.uid)
    .withConverter(getDataConverter<UserProfile>())
    .set({}, { merge: true });
});

const signOut = (auth: firebase.auth.Auth): AppThunk => async dispatch => {
  await auth.signOut();
  dispatch(authSlice.actions.signOut());
  dispatch(push('/'));
};

const dispatchReturnUrl = (getState: any, dispatch: any) => {
  const redirectFrom = (getState().router.location?.state as any)?.from;
  let redirectPath = redirectFrom?.pathname || '';
  redirectPath = redirectPath
    ? (redirectPath as string).concat(redirectFrom?.search)
    : '';

  if (redirectPath) {
    dispatch(push(redirectPath));
  } else if (getState().router.location.pathname.indexOf('sign-in') > -1) {
    dispatch(push('/'));
  }
};

const sendEmailConfirmation = createAsyncThunk(
  'authSlice/emailConfirmation',
  async (
    args: {
      auth: firebase.auth.Auth;
      language: string;
      useRedirectPath: boolean;
    },
    { dispatch, getState }: any
  ) => {
    const { auth, language, useRedirectPath } = args;
    if (
      auth.currentUser &&
      auth.currentUser.email &&
      !auth.currentUser.emailVerified
    ) {
      const redirectPath = (getState().router.location?.state as any)?.from
        ?.pathname;

      if (redirectPath && useRedirectPath) {
        await sendEmailVerification(
          auth,
          language,
          `${window.origin}${redirectPath}`
        );
      } else if (
        getState().router.location.pathname.indexOf('sign-in') > -1 ||
        !useRedirectPath
      ) {
        await sendEmailVerification(auth, language, window.origin);
      }

      if (useRedirectPath) {
        dispatch(push(RouteName.ConfirmYourEmail));
      } else {
        dispatch(push(RouteName.EmailSent));
      }
    }
    dispatchReturnUrl(getState, dispatch);
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isLoading = false;
    },
    signOut(state) {
      state.user = null;
      state.isLoading = false;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(restoreAuth.pending, handlePending);
    builder.addCase(restoreAuth.rejected, handleError);
    builder.addCase(signUp.pending, handlePending);
    builder.addCase(signUp.rejected, handleError);
  }
});

export { restoreAuth, signUp, signOut, sendEmailConfirmation };
export const { signIn } = authSlice.actions;
export default authSlice.reducer;
