import * as React from 'react';

import { Document } from 'documents';

import {
  createQuery,
  db,
  getDataConverter,
  OrderByCondition,
  WhereCondition
} from 'api/firebase';

export const useCollectionSubscription = () => {
  const subscribe = React.useCallback(
    <T extends Document>(
      path: string,
      onSuccess: (value: T[]) => void,
      onError?: () => void,
      where?: WhereCondition | WhereCondition[],
      orderBy?: OrderByCondition
    ) => {
      const dataConverter = getDataConverter<T>();

      const query = createQuery<T>(path, db, dataConverter, where, orderBy);

      const unsubscribe = query.onSnapshot(
        snapshot => {
          const items: T[] = [];
          snapshot.forEach(doc => items.push(doc.data()));

          onSuccess(items);
        },
        err => {
          onError?.();
          console.error(err);
        }
      );

      return unsubscribe;
    },
    []
  );

  return subscribe;
};
