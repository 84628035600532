import './firebaseui-styling.global.scss';
import './services/i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';

import store, { history } from 'app/store';

import reportWebVitals from './reportWebVitals';

const render = () => {
  const App = require('./app/App').default;

  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback={<CircularProgress />}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
