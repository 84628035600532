import './FormSubList.scss';

import * as React from 'react';

import clsx from 'clsx';

export interface FormSubListProps extends React.HTMLAttributes<HTMLDivElement> {
  headers?: string[];
}

export const FormSubList: React.FC<FormSubListProps> = ({
  headers,
  className,
  children
}) => (
  <div className={clsx('form-sub-list', className)}>
    {headers && (
      <div className="form-sub-list__headers list-headers">
        {headers.map(x => (
          <h3 key={x}>{x}</h3>
        ))}
      </div>
    )}
    <div className="form-sub-list__list">{children}</div>
  </div>
);
