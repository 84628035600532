export function loadScript(src: string, name: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const old_script = Array.from(document.getElementsByTagName('script')).find(
      s => s.src.indexOf(name) > -1
    );
    if (old_script) {
      old_script.remove();
    }

    const script = document.createElement('script');
    script.src = src;
    script.defer = true;

    const onScriptLoad = () => resolve();

    const onScriptError = (e: ErrorEvent) => {
      script?.remove();
      reject(e);
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.append(script);
  });
}
