import './FeedbackDialog.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  makeStyles,
  Snackbar,
  Theme,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { getAssistantTitle } from 'constants/assistant-type';
import { Collection, Mail, Setting } from 'documents';

import { createRepo } from 'api/firebase';

import { Form, FormInput } from 'components';

export interface Feedback {
  title: string;
  message: string;
  email: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 3, 3)
    }
  })
);

export const FeedbackDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const mailRepo = createRepo<Mail>(`${Collection.Mail}`);
  const settingsRepo = createRepo<Setting>(`${Collection.Settings}`);

  const onSubmit = async (data: Feedback) => {
    setLoading(true);
    const settings = await settingsRepo.first();
    if (settings) {
      const assistantType = getAssistantTitle();

      await mailRepo.add({
        to: settings.adminEmailAddress,
        message: {
          subject: `${assistantType} Assistant: ${t('New feedback')}: ${
            data.title
          }`,
          text: `
${t('Message')}: ${data.message}
${t('Email')}: ${data.email}
            `
        }
      });
    }
    setLoading(false);
    setOpen(false);
    setShow(true);
  };

  return (
    <div className="feedback-dialog">
      {sm ? (
        <Link
          component="a"
          variant="body2"
          className="feedback-dialog__feedback-link"
          onClick={() => setOpen(true)}
        >
          <img src="/img/feedback_icon.svg" alt="feedback icon" />
        </Link>
      ) : (
        <Button
          variant="outlined"
          className="feedback-dialog__feedback-button"
          onClick={() => setOpen(true)}
        >
          {t('Feedback')}
        </Button>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen={sm}>
        <Form onSubmit={onSubmit}>
          <DialogContent>
            <FormInput
              margin="dense"
              id="title"
              name="title"
              label={t('Title')}
              fullWidth
              variant="outlined"
              color="secondary"
              required
              autoFocus
            />
            <FormInput
              margin="dense"
              id="message"
              name="message"
              label={t('Your text')}
              multiline
              rows="10"
              fullWidth
              variant="outlined"
              color="secondary"
            />
            <FormInput
              margin="dense"
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              color="secondary"
              required
            />
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => setOpen(false)}
            >
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              color="secondary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="secondary" size="1.75em" />
              ) : (
                t('Send')
              )}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={show}
        autoHideDuration={2000}
        onClose={() => setShow(false)}
        message={t('Your feedback has been successfully submitted.')}
      />
    </div>
  );
};
