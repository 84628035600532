import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';

import { FormInputProps } from './types';

const FormCheckbox: React.FC<FormInputProps & CheckboxProps> = React.memo(
  ({ name, label, ...rest }) => {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...rest}
                checked={value ?? false}
                onChange={e => onChange(e.target.checked)}
                name={name}
              />
            }
            label={label}
          />
        )}
      />
    );
  }
);

FormCheckbox.displayName = 'FormCheckbox';
export { FormCheckbox };
