import { Document } from 'documents';
import firebase from 'firebase/app';
import moment from 'moment';

function convertFrom(obj: { [field: string]: any }) {
  Object.keys(obj).forEach(k => {
    const value = obj[k];
    if (value instanceof firebase.firestore.Timestamp) {
      obj[k] = moment.utc((value as firebase.firestore.Timestamp).toDate());
    } else if (value != null && typeof value === 'object') {
      convertFrom(value);
    }
  });
}

function convertTo(obj: { [field: string]: any }) {
  Object.keys(obj)
    .filter(k => k !== 'metadata')
    .forEach(k => {
      const value = obj[k];
      if (value === undefined) {
        obj[k] = null;
      } else if (moment.isMoment(value)) {
        // .utc(true): we convert TZ to UTC w/o changing the datetime value.
        // That's how we agree to keep dates in Firestore (see README.md, "Working with Dates in CRx" for details)
        obj[k] = (value as moment.Moment).utc(true).toDate();
      } else if (value != null && typeof value === 'object') {
        convertTo(value);
      }
    });
}

/**
 * Converts data from db to app representation
 */
export function getDataConverter<
  T extends Document
>(): firebase.firestore.FirestoreDataConverter<T> {
  return {
    toFirestore: function (document: T) {
      const { id, ...data } = document;

      convertTo(data);

      const metadata = {
        schemaVersion: process.env.REACT_APP_DB_SCHEME_VERSION,
        created:
          document.metadata?.created?.utc(true).toDate() ||
          firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp()
      };

      return {
        ...data,
        metadata
      };
    },

    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options);

      convertFrom(data);

      return {
        id: snapshot.id,
        ...data
      } as T;
    }
  };
}
