import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Language = 'en' | 'ru';

export interface AppState {
  activeLang: Language;
}

const initialState: AppState = {
  activeLang: 'en'
};

const slice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setActiveLang: (state, { payload: lang }: PayloadAction<Language>) => {
      state.activeLang = lang;
    }
  }
});

export const { setActiveLang } = slice.actions;
export default slice.reducer;
