import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Grid, Typography } from '@material-ui/core';
import { QFormSection, QFormSectionItem } from 'documents';

import { useDebounce, useReducer } from 'hooks';

import { Page } from 'components';

import { Questions } from './questions';
import { Sections } from './sections';
import { useStyles } from './SectionsQuestions.styles';
import sectionsQuestionsReducer, {
  addQuestion,
  addSection,
  initialState,
  loadQuestions,
  loadSections,
  removeQuestion,
  removeSection,
  saveQuestion,
  saveSection,
  selectSection,
  updateQuestion,
  updateSection
} from './sectionsQuestionsSlice';

const debounceWait = 500;

export interface SectionsQuestionsProps {
  formId: string;
}

export const SectionsQuestions: React.FC<SectionsQuestionsProps> = ({
  formId
}: SectionsQuestionsProps) => {
  const [state, dispatch] = useReducer(sectionsQuestionsReducer, initialState);
  const { t } = useTranslation();

  const classes = useStyles();

  React.useEffect(() => {
    dispatch(loadSections(formId));
  }, [dispatch, formId]);

  React.useEffect(() => {
    dispatch(loadQuestions());
  }, [dispatch, state.activeSectionId]);

  const debouncedSectionSave = useDebounce(
    (item: QFormSection) => dispatch(saveSection(item)),
    debounceWait
  );
  const debouncedQuestionSave = useDebounce(
    (item: QFormSectionItem) => dispatch(saveQuestion(item)),
    debounceWait
  );

  const handleQuestionChange = React.useCallback(
    (item: QFormSectionItem) => {
      if (item.id) {
        dispatch(updateQuestion(item));
        debouncedQuestionSave(item);
      } else {
        dispatch(addQuestion(item));
      }
    },
    [debouncedQuestionSave]
  );

  const handleQuestionRemove = React.useCallback((item: QFormSectionItem) => {
    dispatch(removeQuestion(item));
  }, []);

  const handleSectionSelect = React.useCallback(
    (id?: string) => dispatch(selectSection(id)),
    []
  );

  const handleSectionChange = React.useCallback(
    (item: QFormSection) => {
      if (item.id) {
        dispatch(updateSection(item));
        debouncedSectionSave(item);
      } else {
        dispatch(addSection(item));
      }
    },
    [debouncedSectionSave]
  );

  const handleSectionRemove = React.useCallback(
    (item: QFormSection) => dispatch(removeSection(item)),
    []
  );

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} sm={4}>
        <Card className={classes.card}>
          <Typography variant="h6" className={classes.header}>
            {t('Sections')}
          </Typography>
          <Page
            className={classes.container}
            isLoading={state.sectionStatus.isLoading}
            hasError={state.sectionStatus.hasError}
          >
            <Sections
              items={state.sections}
              activeItemId={state.activeSectionId}
              onSelect={handleSectionSelect}
              onChange={handleSectionChange}
              onRemove={handleSectionRemove}
            />
          </Page>
        </Card>
      </Grid>

      <Grid item xs={12} sm={8}>
        <Card className={classes.card}>
          <Typography variant="h6" className={classes.header}>
            {t('Questions')}
          </Typography>
          <Page
            className={classes.container}
            isLoading={state.questionStatus.isLoading}
            hasError={state.questionStatus.hasError}
            maxWidth="xl"
          >
            {state.activeSectionId && (
              <Questions
                items={state.questions}
                onChange={handleQuestionChange}
                onRemove={handleQuestionRemove}
              />
            )}
          </Page>
        </Card>
      </Grid>
    </Grid>
  );
};
