import React from 'react';

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

import { EnhancedTableHeaderProps } from 'components/enhanced-table/EnhancedTable.models';

export default function EnhancedTableHeader<T>(
  props: EnhancedTableHeaderProps<T>
) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    numSelected,
    onSelectAllClick,
    rowsCount,
    headerCells,
    checkboxSelection
  } = props;
  const createSortHandler = (property: keyof any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property as any);
  };

  return (
    <TableHead>
      <TableRow>
        {checkboxSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowsCount}
              checked={rowsCount > 0 && numSelected === rowsCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headerCells
          .filter(column => !column.breakpointDown && !column.breakpointUp)
          .map(column => (
            <TableCell
              className={classes.head}
              key={column.id as string}
              align={column.align || 'left'}
              padding="default"
              sortDirection={orderBy === column.id ? order : false}
            >
              {!!column.sortable && (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
              {!column.sortable && column.label}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}
