import * as React from 'react';

import classnames from 'classnames';

import { WizardContext } from './Wizard';
import { useStyles } from './WizardPage.styles';

export interface WizardPageProps {
  id: string;
}

export const WizardPage: React.FC<WizardPageProps> = ({ id, children }) => {
  const classes = useStyles();
  const activeId = React.useContext(WizardContext);

  return (
    <section
      className={classnames(classes.container, {
        [classes.hidden]: activeId !== id
      })}
    >
      {children}
    </section>
  );
};
