import React from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import { auth } from 'api/firebase';
import { signUp } from 'features/auth/authSlice';
import { LocalizedAuthForm } from './LocalizedAuthForm';
import { useTranslation } from 'react-i18next';

export const FirebaseSignIn: React.FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const signInSuccessWithAuthResult = (
    authResult: firebase.auth.UserCredential
  ) => {
    if (authResult.user && authResult?.additionalUserInfo?.isNewUser) {
      dispatch(signUp({ auth, language: i18n.language }));
    }

    return false;
  };

  const uiConfig: any = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    credentialHelper: 'none',
    callbacks: { signInSuccessWithAuthResult }
  };

  return <LocalizedAuthForm config={uiConfig} auth={auth} />;
};
