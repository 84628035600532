import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import firebase from 'firebase/app';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const cloudFunctions = firebase.functions();
export const db = firebase.firestore();
export const storage = firebase.storage();

// Firebase emulator setup
if (process.env.REACT_APP_USE_FIREBASE_EMULATOR) {
  db.settings({
    host: 'localhost:8088',
    ssl: false
  });
  firebase.functions().useEmulator('localhost', 5001);
}

/** Adds user role to the current user, cloud function */
export const addCustomerRole: () => Promise<firebase.functions.HttpsCallableResult> = () =>
  cloudFunctions.httpsCallable('addCustomerRole')();

/** Claims pdf generating cloud function */
export const claimsPdf: (
  id: string,
  lang: string
) => Promise<firebase.functions.HttpsCallableResult> = (id, lang) =>
  cloudFunctions.httpsCallable('claimsPdf')({
    id,
    lang,
    assistantType: process.env.REACT_APP_ASSISTANT_TYPE!
  });

/** Copies claims and assigns vendor */
export const assignVendors: (
  vendorsIds: string[],
  claimsIds: string[],
  userId: string,
  lang: string,
  fileNameTemplate: string,
  subject: string,
  text: string
) => Promise<firebase.functions.HttpsCallableResult> = (
  vendorsIds,
  claimsIds,
  userId,
  lang,
  fileNameTemplate,
  subject,
  text
) =>
  cloudFunctions.httpsCallable('assignVendors')({
    vendorsIds,
    claimsIds,
    userId,
    lang,
    assistantType: process.env.REACT_APP_ASSISTANT_TYPE!,
    fileNameTemplate,
    subject,
    text
  });

/** Sends email with claim specific info */
export const sendClaimAsEmail: (
  claimId: string,
  lang: string,
  subject: string,
  text: string,
  fileNameTemplate: string,
  toAddresses: string[],
  vendorName?: string
) => Promise<firebase.functions.HttpsCallableResult> = (
  claimId,
  lang,
  subject,
  text,
  fileNameTemplate,
  toAddresses,
  vendorName
) =>
  cloudFunctions.httpsCallable('sendClaimAsEmail')({
    vendorName,
    claimId,
    lang,
    assistantType: process.env.REACT_APP_ASSISTANT_TYPE!,
    subject,
    text,
    fileNameTemplate,
    toAddresses
  });

export const sendEmailVerification = (
  auth: firebase.auth.Auth,
  language: string,
  url: string = ''
) => {
  auth.languageCode = language;
  if (auth && auth.currentUser) {
    if (url) {
      return auth.currentUser
        .sendEmailVerification({ url })
        .then()
        .catch(e => console.error('Email sent error', e));
    } else {
      return auth.currentUser
        .sendEmailVerification()
        .then()
        .catch(e => console.error('Email sent error', e));
    }
  }
};
