import { Button, Grid } from '@material-ui/core';
import classNames from 'classnames';

import { useStyles } from './ButtonGrid.styles';

export interface ButtonGridItem {
  id?: string;
  name: string;
  active?: boolean;
}

export interface ButtonGridProps {
  className?: string;
  items: ButtonGridItem[];
  onSelect: (id: string) => void;
  selected?: string;
}

export const ButtonGrid: React.FC<ButtonGridProps> = ({
  className,
  items,
  onSelect,
  selected
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      className={classNames('button-grid', className)}
    >
      {items.map(x => (
        <Grid item key={x.id!}>
          <Button
            variant="outlined"
            className={classNames(
              classes.btn,
              selected && selected === x.id && classes.selected,
              x.active !== undefined && !x.active && classes.inactive
            )}
            onClick={() => onSelect(x.id!)}
          >
            {x.name}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
