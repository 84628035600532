import * as React from 'react';

import clsx from 'clsx';
import { QFormSectionItem } from 'documents';

import { DeleteItemType, Quantifier } from '../../deletion-dialog';
import {
  DeleteOptions,
  FormSubList,
  FormSubListItem,
  FormSubListNewItem
} from '../../form-sublist';
import { QuestionItem } from '../question-item';
import { useStyles } from './Questions.styles';

export interface QuestionsProps {
  items: QFormSectionItem[];
  onChange: (item: QFormSectionItem) => void;
  onRemove: (item: QFormSectionItem) => void;
}

export const Questions: React.FC<QuestionsProps> = ({
  items,
  onChange,
  onRemove
}: QuestionsProps) => {
  const classes = useStyles();

  const [activeItemId, setActiveItemId] = React.useState<string>();
  const handleNewItemClick = React.useCallback(
    () => setActiveItemId(undefined),
    []
  );

  return (
    <FormSubList>
      {items.map(item => (
        <FormSubListItem
          key={item.id!}
          value={item}
          onRemove={onRemove}
          onChange={onChange}
          deleteOptions={deleteOptions}
          className={clsx(
            classes.listItem,
            activeItemId === item.id && classes.activeListItem
          )}
          onClick={() => setActiveItemId(item.id)}
        >
          <QuestionItem active={activeItemId === item.id} editOnClick />
        </FormSubListItem>
      ))}
      <FormSubListNewItem
        className={clsx(
          classes.listItem,
          !activeItemId && classes.activeListItem
        )}
        onClick={handleNewItemClick}
        onAddClick={onChange}
        validate={newItemValidator}
      >
        <QuestionItem active={!activeItemId} />
      </FormSubListNewItem>
    </FormSubList>
  );
};

const deleteOptions: DeleteOptions = {
  type: DeleteItemType.Question,
  quantifier: Quantifier.One
};

const newItemValidator = (x: QFormSectionItem) => Boolean(x.question);
