import { t } from 'utils';

export enum AssistantType {
  It = 'it', // values should match REACT_APP_ASSISTANT_TYPE env var value
  Fin = 'fin',
  ReadyAstra = 'ready-astra',
  Med = 'med'
}

export const getAssistantTitle = () =>
  ({
    [AssistantType.It]: 'IT',
    [AssistantType.Fin]: 'Finance',
    [AssistantType.ReadyAstra]: 'Astra Ready',
    [AssistantType.Med]: 'Medical'
  }[process.env.REACT_APP_ASSISTANT_TYPE! as AssistantType] || 'Not Found');

export const getAssistantMakeRequestTitle = () =>
  ({
    [AssistantType.It]: t('Make Request for IT Solution'),
    [AssistantType.Fin]: t('Make Request for Financial Solution'),
    [AssistantType.ReadyAstra]: t('Make Request for IT Solution'),
    [AssistantType.Med]: t('Make Request for Medical Solution')
  }[process.env.REACT_APP_ASSISTANT_TYPE! as AssistantType] || 'Not Found');

export const getRussianOnlyAssistantTypes = () => [
  AssistantType.It.toString(),
  AssistantType.Fin.toString(),
  AssistantType.Med.toString()
];
