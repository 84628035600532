import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';

import { Spinner } from 'components/spinner/Spinner';

import { useStyles } from './Page.styles';

export interface PageProps {
  className?: string;
  isLoading?: boolean;
  hasError?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  emptyText?: string;
}

export const Page: React.FC<PropsWithChildren<PageProps>> = ({
  className,
  isLoading,
  maxWidth,
  hasError,
  children,
  emptyText
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container
      maxWidth={maxWidth ?? 'lg'}
      className={classNames(classes.page, className)}
    >
      {!hasError && (isLoading || emptyText) && (
        <Spinner isLoading={isLoading} size={48} emptyText={emptyText} />
      )}
      {hasError && (
        <Alert severity="info">{t('Something went wrong')}...</Alert>
      )}
      {!hasError && !isLoading && children}
    </Container>
  );
};
