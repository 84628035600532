import './ProfileMenu.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import {
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { RouteName } from 'route';
import { t } from 'utils';

import { LittleLogo } from 'components/LittleLogo';
import { Role, User } from 'features/auth';

export interface ProfileMenuProps {
  user: User;
  signOut: () => void;
}

const customerMenuItems = [
  {
    label: t('Home'),
    route: RouteName.Home
  },
  {
    label: t('Main configurator'),
    route: RouteName.ClaimForm.split('/:')[0]
  },
  {
    label: t('My account'),
    route: RouteName.Account
  },
  {
    label: t('Profile'),
    route: RouteName.Profile
  }
];

const adminMenuItems = [
  {
    label: t('Claims'),
    route: RouteName.Claims
  },
  {
    label: t('Decision Matrix'),
    route: RouteName.AdminDecisionMatrix
  },
  {
    label: t('Questionnaires'),
    route: RouteName.Questionnaires
  }
];

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ user, signOut }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { t } = useTranslation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems =
    user.role === Role.Customer ? customerMenuItems : adminMenuItems;

  return (
    <>
      <Button
        variant="text"
        endIcon={sm ? null : <ExpandMore />}
        className="profile-link"
        onClick={handleClick}
      >
        <LittleLogo>{user.displayName}</LittleLogo>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((x, i) => (
          <MenuItem
            key={i}
            component={RouterLink}
            to={x.route}
            onClick={handleClose}
          >
            {t(x.label)}
          </MenuItem>
        ))}
        <MenuItem onClick={signOut}>{t('Logout')}</MenuItem>
      </Menu>
    </>
  );
};
