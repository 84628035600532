import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DecisionMatrixLeaf } from 'documents';
import { useTranslation } from 'react-i18next';

import { MakeRequestButton } from './MakeRequestButton';

export interface ClaimsLeafInfoProps {
  leaf: DecisionMatrixLeaf;
  showHtmlDesc?: boolean;
  showBusinessTasksRelations?: boolean;
  tasks?: DecisionMatrixLeaf[];
  title?: string;
  businessTaskId?: string;
}

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase'
  },
  tasks: {
    listStyle: 'none',
    paddingLeft: 0
  }
});

export const ClaimsLeafInfo: React.FC<ClaimsLeafInfoProps> = ({
  tasks,
  leaf,
  showHtmlDesc,
  showBusinessTasksRelations,
  title,
  businessTaskId
}) => {
  const jss = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Grid container>
        {showHtmlDesc && leaf.htmlDesc && (
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" className={jss.title}>
              {t('Program Description')}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: leaf.htmlDesc }} />
          </Grid>
        )}
        {(showBusinessTasksRelations ?? true) && title && tasks && (
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" className={jss.title}>
              {title}:
            </Typography>
            <ul className={jss.tasks}>
              {tasks.map(x => (
                <li key={x.id!}>{x.name}</li>
              ))}
            </ul>
          </Grid>
        )}
      </Grid>
      {leaf.active && (
        <Grid container justify="center">
          <MakeRequestButton leafId={leaf.id!} businessTaskId={businessTaskId!} />
        </Grid>
      )}
    </div>
  );
};
