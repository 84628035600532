import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { AssistantType } from 'constants/assistant-type';

import { useStyles } from './Intro.styles';

export const Intro: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Typography variant="h3" paragraph className={classes.intro}>
      {process.env.REACT_APP_ASSISTANT_TYPE === AssistantType.It && (
        <Trans i18nKey={'matrix-intro-it'} t={t}>
          IT <span>Assistant</span> is one-stop webapp for configuring &amp;
          sales promotion of different high-tech solutions
        </Trans>
      )}
      {process.env.REACT_APP_ASSISTANT_TYPE === AssistantType.Fin && (
        <Trans i18nKey={'matrix-intro-fin'} t={t}>
          Finance <span>Assistant</span> is one-stop webapp for configuring
          &amp; sales promotion of financial services, as well as the effective
          interaction of all parties to the transaction
        </Trans>
      )}
      {process.env.REACT_APP_ASSISTANT_TYPE === AssistantType.ReadyAstra && (
        <Trans i18nKey={'matrix-intro-ready-astra'} t={t}>
          <span>Astra Ready</span> is a system for solving problems in the field
          of Import Substitution and Security in organizations
        </Trans>
      )}
      {process.env.REACT_APP_ASSISTANT_TYPE === AssistantType.Med && (
        <Trans i18nKey={'matrix-intro-med'} t={t}>
          Medical <span>Assistant</span> - это универсальное веб-приложение для
          подбора и продвижения медицинских решений и услуг
        </Trans>
      )}
    </Typography>
  );
};
