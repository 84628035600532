import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Menu, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { getRussianOnlyAssistantTypes } from 'constants/assistant-type';
import {
  bindMenu,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import moment from 'moment';
import { RouteName } from 'route';

import { RootState } from 'app/rootReducer';

import { languages } from '../../services';

export const LanguageSwitch: React.FC = () => {
  const { t, i18n } = useTranslation();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'language-menu-popup'
  });

  const location = useSelector((state: RootState) => state.router.location);

  useEffect(() => {
    moment.locale(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  const handleMenuItemClick = (lng: string) => {
    if (location.pathname.includes(RouteName.ClaimForm.split(':')[0])) {
      const confirmed = window.confirm(
        t(
          'Page will be reloaded. Unsaved changes will be lost. Do you really want to proceed?'
        )
      );
      if (confirmed) {
        changeLanguage(lng);
      }
    } else {
      changeLanguage(lng);
    }

    popupState.close();
  };

  return !getRussianOnlyAssistantTypes().includes(
    process.env.REACT_APP_ASSISTANT_TYPE!
  ) ? (
    <div>
      <Button
        variant="text"
        endIcon={<ExpandMore />}
        {...bindTrigger(popupState)}
      >
        {i18n.language}
      </Button>
      <Menu {...bindMenu(popupState)}>
        {languages.map(l => (
          <MenuItem key={l} onClick={() => handleMenuItemClick(l)}>
            {l.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  ) : null;
};
