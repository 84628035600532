import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginBottom: theme.spacing(2)
    },
    spin: {
      flex: 1
    }
  })
);
