import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    wizard: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2)
    },
    buttonContainer: {
      display: 'flex',
      gap: theme.spacing(2)
    }
  })
);
