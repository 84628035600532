import { DecisionMatrixLeaf, Document } from 'documents';
import { filter, flow, sortBy, values } from 'lodash/fp';

export const normalize = <T extends Document>(arr: T[]) =>
  arr.reduce((acc, x) => {
    acc[x.id!] = x;
    return acc;
  }, {} as Record<string, T>);

export const denormalize = <T extends Record<string, { order: number }>>(
  obj: T
) =>
  flow(
    values,
    sortBy(x => x.order)
  )(obj);

export const denormalizeLeaves = (
  leaves: Record<string, DecisionMatrixLeaf>,
  selectedNodeId: string
) =>
  flow(
    denormalize,
    filter((x: DecisionMatrixLeaf) => x.node === selectedNodeId)
  )(leaves);

export const getRelatedBusinessTasks = (
  leaves: Record<string, DecisionMatrixLeaf>,
  selectedLeafId: string
) => {
  return flow(
    values,
    filter(
      (x: DecisionMatrixLeaf) =>
        x.claimsMatrixLeaves?.includes(selectedLeafId) ?? false
    ),
    sortBy((x: DecisionMatrixLeaf) => x.order)
  )(leaves);
};
