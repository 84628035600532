import { Document } from './document';

export interface QForm extends Document {
  name: string;
}

export interface QFormSection extends Document {
  title?: string;
  order?: number;
}

export const QFormAnswerTypes = [
  'input',
  'textarea',
  'radio',
  'checkbox'
] as const;
export type QFormAnswerType = typeof QFormAnswerTypes[number];

export interface QFormSectionItem extends Document {
  question?: string;
  answerType?: QFormAnswerType;
  answerOptions?: string[];
  order?: number;
}

export interface QFormStash extends Document {
  qFormId: string;
}

export interface QFormStashItem extends Document {
  stashed: string | string[];
}
