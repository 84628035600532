import './SignInButton.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@material-ui/core';
import { RouteName } from 'route';

import { LittleLogo } from 'components/LittleLogo';

export const SignInButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Link component={RouterLink} className="sign-in-btn" to={RouteName.SignIn}>
      <LittleLogo>{t('Log IN / Create one')}</LittleLogo>
    </Link>
  );
};
