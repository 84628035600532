import { FC, forwardRef, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import moment from 'moment';

interface FormDatePickerProps {
  name: string;
  min?: moment.Moment;
}

const FormDatePicker: FC<
  FormDatePickerProps & Omit<DatePickerProps, 'value' | 'onChange'>
> = memo(
  forwardRef(
    (
      { name, placeholder, required = false, defaultValue, min, ...rest },
      ref
    ) => {
      const { control } = useFormContext();
      const { t } = useTranslation();

      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ?? null}
          rules={{
            required: required ? String(t('This field is required')) : undefined
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker
                placeholder={placeholder}
                required={required}
                error={invalid}
                helperText={error?.message}
                minDate={min}
                autoOk
                variant="inline"
                format={t('MM/DD/YYYY')}
                inputVariant="outlined"
                {...rest}
                {...field}
                ref={ref}
              />
            </MuiPickersUtilsProvider>
          )}
        />
      );
    }
  )
);

FormDatePicker.displayName = 'FormDatePicker';
export { FormDatePicker };
