import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import { buildUrl, RouteName } from 'route';

export interface MakeRequestButtonProps {
  leafId: string;
  businessTaskId?: string;
}

export const MakeRequestButton: React.FC<MakeRequestButtonProps> = ({
  leafId,
  businessTaskId
}) => {
  const { t } = useTranslation();
  return (
    <Button
      color="secondary"
      variant="contained"
      component={Link}
      to={buildUrl(RouteName.ClaimForm, { leafId }, { businessTaskId })}
    >
      {t('Make Request')}
    </Button>
  );
};
