import { PageState } from './page-state';

export interface PageError {
  error: { code?: string };
}

export const handleError = (state: PageState, { error }: PageError) => {
  state.isLoading = false;
  state.isUpdating = false;
  state.hasError = true;

  console.error(error);

  if (error?.code === 'permission-denied') {
    state.permissionError = true;
  }
};

export const handlePending = (state: PageState) => {
  state.isLoading = true;
  state.hasError = false;
};
export const handleUpdating = (state: PageState) => {
  state.isUpdating = true;
};
