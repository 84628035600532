import * as React from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel
} from '@material-ui/core';

import { CheckboxGroupOption } from './types';

export interface CheckboxGroupProps {
  name?: string;
  label?: string;
  value?: string[];
  options: CheckboxGroupOption[];
  onChange?: (value: string[]) => void;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  className?: string;
}
const CheckboxGroup: React.FC<CheckboxGroupProps> = React.memo(
  ({
    name,
    className,
    error,
    errorMessage,
    label,
    onChange,
    options,
    required,
    value
  }) => {
    const [state, setState] = React.useState<Record<string, boolean>>({});

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const newState = { ...state, [e.target.value]: checked };
        setState(newState);
        onChange?.(Object.keys(newState).filter(x => newState[x]));
      },
      [onChange, state]
    );

    React.useEffect(() => {
      setState(
        options.reduce((acc, x) => {
          acc[x.value] = value?.includes(x.value) ?? false;
          return acc;
        }, {} as Record<string, boolean>)
      );
    }, [options, value]);

    return (
      <FormControl
        className={className}
        required={required}
        error={error}
        component="fieldset"
      >
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <FormGroup>
          {options.map(x => (
            <FormControlLabel
              key={x.value}
              control={
                <Checkbox
                  checked={state[x.value] ?? false}
                  onChange={handleChange}
                  name={name}
                  value={x.value}
                />
              }
              label={x.label}
            />
          ))}
        </FormGroup>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  }
);

CheckboxGroup.displayName = 'CheckboxGroup';

export { CheckboxGroup };
