import { enUS, ruRU } from '@material-ui/core/locale';
import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createMuiTheme
} from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { AssistantType } from 'constants/assistant-type';

const commonPalette: PaletteOptions = {
  primary: {
    main: '#363636'
  },
  secondary: {
    main: '#ff4747'
  }
};

const palettes: Record<AssistantType, PaletteOptions> = {
  [AssistantType.ReadyAstra]: {
    primary: {
      main: '#00a0ff'
    },
    secondary: {
      main: '#0089db'
    }
  },
  [AssistantType.Fin]: commonPalette,
  [AssistantType.It]: commonPalette,
  [AssistantType.Med]: commonPalette
};

export const appTheme = (lang: string, assistantType?: AssistantType) =>
  responsiveFontSizes(
    createMuiTheme(
      {
        palette: assistantType && palettes[assistantType],
        typography: {
          fontFamily: [
            'GEOSLAB',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif'
          ].join(','),
          fontSize: 12,
          fontWeightBold: 600
        }
      },
      lang === 'ru' ? ruRU : enUS
    )
  );
