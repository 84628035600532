import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@material-ui/core';
import { AssistantType, getAssistantTitle } from 'constants/assistant-type';

import { useAppLogoStyles } from './AppLogo.styles';

export const AppLogo: React.FC = () => {
  const classes = useAppLogoStyles();
  const name = getAssistantTitle();
  return (
    <Link component={RouterLink} to="/" className={classes.appBar}>
      {process.env.REACT_APP_ASSISTANT_TYPE === AssistantType.ReadyAstra ? (
        <img
          src="/img/ready-astra-logo.svg"
          alt="logo"
          className={classes.readyAstraLogo}
        />
      ) : (
        <>
          <img src="/img/logo.svg" alt="logo" className={classes.logo} />
          <span className={classes.name}>{name}</span>{' '}
          <span>
            Assistant<span className={classes.regMark}>&#174;</span>
          </span>
        </>
      )}
    </Link>
  );
};
