import { ClaimStatus } from '../../../../documents';
import { t } from '../../../../utils';

export const mapStatus = (status: ClaimStatus): string => {
  switch (status) {
    case ClaimStatus.Approved:
      return t('Approved');
    case ClaimStatus.Correction:
      return t('Correction');
    case ClaimStatus.Completed:
      return t('Completed');
    case ClaimStatus.InDevelopment:
      return t('In progress');
    case ClaimStatus.ReceivedResponse:
      return t('Response received');
    default:
      return '';
  }
};
