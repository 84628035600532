import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useAppBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow:
        '0 0 1px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04), 0 10px 20px rgba(0, 0, 0, 0.04)'
    },
    appBarToolbar: {
      background: '#fafafa',
      justifyContent: 'space-between',

      [theme.breakpoints.up('md')]: {
        height: '110px',
        padding: '0 110px'
      },

      [theme.breakpoints.down('sm')]: {
        height: '60px',
        padding: '0 16px'
      }
    },
    appBarSpacer: {
      flexGrow: 1
    },
    appBarLogo: {
      [theme.breakpoints.down('sm')]: {
        width: '162px'
      },
      [theme.breakpoints.up('md')]: {
        width: '244px'
      }
    }
  })
);
