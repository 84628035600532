import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 0)
      }
    },
    head: {
      fontWeight: 600
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    hover: {
      cursor: 'pointer'
    },
    bold: {
      '& td': {
        fontWeight: 700
      }
    },
    italic: {
      '& td': {
        fontStyle: 'italic'
      }
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    noData: {
      textAlign: 'center'
    },
    paginatorToolbar: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1)
      }
    },
    paginatorActions: {
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(0)
      }
    },
    paginatorSelectRoot: {
      [theme.breakpoints.down('xs')]: {
        marginRight: theme.spacing(1)
      }
    }
  })
);
