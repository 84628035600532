import React, { ReactNode } from 'react';

import { Filter, FilterValue } from './filter/Filter.types';

export const INITIAL_PER_PAGE = 10;
export const PER_PAGE_LIST = [10, 15, 25];

export interface EnhancedHeaderCell {
  id: string;
  label: string;
  width?: string;
  sortable?: boolean;
  breakpointDown?: boolean;
  breakpointUp?: boolean;
  align?: 'left' | 'right';
  type?: EnhancedColumnType;
  tooltip?: string;
  format?: (value: any) => JSX.Element | string;
}

export type EnhancedColumnOrder = 'asc' | 'desc';
export type EnhancedColumnType = 'Basic' | 'Date' | 'DateTime' | 'Icon';

export interface EnhancedTableToolbarProps {
  tableName: string;
  filters?: Filter[];
  initialFiltersValue?: FilterValue[];
  extra?: ReactNode;
  onFilterChange: (filtersValue: FilterValue[]) => void;
}

export interface EnhancedTableHeaderProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: EnhancedColumnOrder;
  orderBy: string;
  rowsCount: number;
  headerCells: EnhancedHeaderCell[];
  rows: T[];
  classes: any;
  numSelected: number;
  checkboxSelection?: boolean;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface EnhancedTablePaginatorProps {
  rowsCount: number;
  perPageList: number[];
  page: number;
  perPage: number;
  initialPerPage: number;
  onChangePage: (value: number) => void;
  onChangeRowPerPage: (value: number) => void;
}

export interface EnhancedTableProps<T> {
  tableName: string;
  defaultOrder: EnhancedColumnOrder;
  defaultOrderBy: string;
  initialRowsCount: number;
  headerCells: EnhancedHeaderCell[];
  rows: T[];
  highlightedRowIds?: string[];
  italicRowIds?: string[];
  initialPerPage: number;
  perPageList: number[];
  onRowClick?: (row: T) => void;
  onSelected?: (selected: T[]) => void;
  checkboxSelection?: boolean;
  filters?: Filter[];
  initialFiltersValue?: FilterValue[];
  selected: T[];
}

export interface EnhancedTableProp<T> {
  tableProps: EnhancedTableProps<T>;
  toolbar?: {
    extra: ReactNode;
  };
}
