import * as React from 'react';

import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';

import { useStyles } from './LoadingButton.styles';

export interface LoadingButtonProps {
  loading?: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps & ButtonProps> = React.memo(
  ({ loading, className, children, ...props }) => {
    const classes = useStyles();

    return (
      <div className={classes.wrapper}>
        <Button
          className={classnames(className, classes.btn)}
          {...props}
          disabled={loading}
        >
          {children}
        </Button>
        {loading && <CircularProgress size={24} className={classes.progress} />}
      </div>
    );
  }
);

LoadingButton.displayName = 'LoadingButton';
export { LoadingButton };
