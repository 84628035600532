import 'moment/locale/ru';
import './App.scss';

import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import StylesProvider from '@material-ui/styles/StylesProvider';
import { AssistantType } from 'constants/assistant-type';

import { auth } from 'api/firebase';
import { useJivo } from 'hooks';

import { Footer } from 'components';
import { AppBar, AppContent } from 'features';
import { restoreAuth, signOut } from 'features/auth';

import { appTheme } from './app-theme';
import { Language, setActiveLang } from './appSlice';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useJivo();

  useEffect(() => {
    dispatch(restoreAuth(auth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onLangChanged = async (lang: string) => {
      dispatch(setActiveLang(lang as Language));
    };

    dispatch(setActiveLang(i18n.language as Language));
    i18n.on('languageChanged', onLangChanged);

    return () => {
      i18n.off('languageChanged', onLangChanged);
    };
  }, [dispatch, i18n]);

  const handleSignOut = useCallback(() => dispatch(signOut(auth)), [dispatch]);

  return (
    <MuiThemeProvider
      theme={appTheme(
        i18n.language,
        process.env.REACT_APP_ASSISTANT_TYPE as AssistantType
      )}
    >
      <CssBaseline />
      <StylesProvider injectFirst>
        <section className="app-layout">
          <AppBar signOut={handleSignOut} />
          <main className="app-layout__content">
            <AppContent />
          </main>
          <Footer />
        </section>
      </StylesProvider>
    </MuiThemeProvider>
  );
}

export default App;
