import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { getAssistantMakeRequestTitle } from 'constants/assistant-type';
import { DecisionMatrixLeaf, DecisionMatrixNode, MatrixType } from 'documents';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';

import { ConditionalDivider } from '../../ConditionalDivider';
import { ButtonGrid, ButtonGridItem } from '../button-grid';
import { ClaimsLeafInfo } from '../ClaimsLeafInfo';
import { Tree } from '../Tree';
import { useStyles } from './BusinessTasksLeafInfo.styles';

export interface BusinessTasksLeafInfoProps {
  claimMxElems: string[];
  claimMxNodes: Record<string, DecisionMatrixNode>;
  claimMxLeaves: Record<string, DecisionMatrixLeaf>;
  showHtmlDesc?: boolean;
  leafId: string;
}

export const BusinessTasksLeafInfo: React.FC<BusinessTasksLeafInfoProps> = ({
  claimMxElems,
  claimMxNodes,
  claimMxLeaves,
  showHtmlDesc,
  leafId
}) => {
  const [selectedElemId, setSelectedElemId] = useState<string>('');
  const [elems, setElems] = useState<ButtonGridItem[]>([]);

  const { t } = useTranslation();
  const jss = useStyles();

  useEffect(() => {
    setSelectedElemId('');
    setElems(map(x => claimMxNodes[x] ?? claimMxLeaves[x], claimMxElems));
  }, [claimMxElems, claimMxLeaves, claimMxNodes]);

  const allLeaves = !some(x => claimMxNodes[x.id!], elems);
  const isNodeSelected = Boolean(
    selectedElemId && claimMxNodes[selectedElemId]
  );

  return (
    <div>
      {allLeaves ? (
        <Typography variant="subtitle1" className={jss.title}>
          {t(getAssistantMakeRequestTitle())}:
        </Typography>
      ) : (
        <ConditionalDivider />
      )}
      <ButtonGrid
        className={allLeaves ? jss.leaves : jss.nodes}
        items={elems} // claims matrix
        selected={selectedElemId}
        onSelect={setSelectedElemId}
      />
      {selectedElemId &&
        claimMxLeaves[selectedElemId]?.active && (
          <ClaimsLeafInfo
            leaf={claimMxLeaves[selectedElemId]}
            showHtmlDesc={showHtmlDesc}
            showBusinessTasksRelations={false}
            businessTaskId={leafId}
          />
        )}
      {isNodeSelected && <ConditionalDivider />}
      {isNodeSelected && (
        <Tree // claims matrix
          matrixType={MatrixType.Claims}
          nodes={claimMxNodes}
          leaves={claimMxLeaves}
          rootId={selectedElemId}
          renderLeafInfo={selectedLeafId => (
            <ClaimsLeafInfo
              leaf={claimMxLeaves[selectedLeafId]}
              showHtmlDesc={showHtmlDesc}
              showBusinessTasksRelations={false}
            />
          )}
        />
      )}
    </div>
  );
};
