import { Moment } from 'moment';

/**
 * Entity collection names in firestore
 *
 * Add in alphabetical order, please
 */
export enum Collection {
  Claims = 'claims',
  Mail = 'mail',
  QForm = 'qForm',
  Settings = 'settings',
  UserProfiles = 'userProfiles',
  Vendors = 'vendors'
}

export const getDecisionMatricesPath = (lang: string) =>
  `languages/${lang}/decisionMatrices`;

/**
 * DecisionMatrix subcollection names in firestore
 *
 * Add in alphabetical order, please
 */
export enum DecisionMatrixSubCollection {
  Leaves = 'leaves',
  Nodes = 'nodes'
}

/**
 * Claims subcollection names in firestore
 *
 * Add in alphabetical order, please
 */
export enum ClaimsSubcollection {
  ClaimMessages = 'claimMessages',
  Specifications = 'specifications'
}

/**
 * QForm subcollection names in firestore
 *
 * Add in alphabetical order, please
 */
export enum QFormSubcollection {
  SectionItems = 'qFormSectionItems',
  Sections = 'qFormSections',
  Stashes = 'qFormStashes',
  StashItems = 'qFormStashItems'
}

export interface DocumentMetadata {
  schemaVersion: string;
  created: Moment;
  updated: Moment;
}

export interface Document {
  id?: string;
  metadata?: DocumentMetadata;
}

/** Storage paths in firestorage */
export enum StoragePath {
  Claims = 'claims',
  claimVendorsPdfs = 'claim-vendor-pdfs',
  pdfTemplatePath = 'pdf-templates'
}
