import { ClaimStatus } from '../../documents';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DoneIcon from '@material-ui/icons/Done';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { mapStatus } from '../../features/customer/claims/list/claims.maps';
import { Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface StatusMapperProps {
  status: ClaimStatus;
}

export const StatusMapper: React.FC<StatusMapperProps> = (
  props: StatusMapperProps
) => {
  const { t } = useTranslation();
  const tooltip = (icon: ReactElement) => (
    <Tooltip title={t(mapStatus(props.status)) || ''}>{icon}</Tooltip>
  );
  switch (props.status) {
    case ClaimStatus.Approved:
      return tooltip(<ThumbUpAltIcon />);
    case ClaimStatus.Correction:
      return tooltip(<TrackChangesIcon />);
    case ClaimStatus.Completed:
      return tooltip(<DoneIcon />);
    case ClaimStatus.InDevelopment:
      return tooltip(<WorkOutlineIcon />);
    case ClaimStatus.ReceivedResponse:
      return tooltip(<MailOutlineIcon />);
    default:
      return <></>;
  }
};
