import { t } from 'utils/translate';

export const variants = [
  t(`Are you sure you want to delete selected section?`),
  t(`Are you sure you want to delete selected sections?`),
  t(`Are you sure you want to delete selected question?`),
  t(`Are you sure you want to delete selected questions?`),
  t(`Are you sure you want to delete selected questionnaire?`),
  t(`Are you sure you want to delete selected questionnaires?`),
  t(`Are you sure you want to delete selected answer option?`),
  t(`Are you sure you want to delete selected answer options?`)
];
