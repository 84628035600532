import React from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { AssistantType } from 'constants/assistant-type';

const logoSrc =
  process.env.REACT_APP_ASSISTANT_TYPE === AssistantType.ReadyAstra
    ? '/img/ready-astra_little-logo.png'
    : '/img/little-logo.png';

export const LittleLogo: React.FC = ({ children }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <img src={logoSrc} alt="little logo" />
      {sm ? null : children}
    </>
  );
};
