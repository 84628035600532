import React from 'react';

import { TablePagination } from '@material-ui/core';

import { EnhancedTablePaginatorProps } from 'components/enhanced-table/EnhancedTable.models';
import { useStyles } from './EnhancedTable.styles';

export default function EnhancedTablePaginator(
  props: EnhancedTablePaginatorProps
) {
  const classes = useStyles();
  const handleChangePage = (event: unknown, newPage: number) => {
    props.onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPerPage = parseInt(event.target.value);
    props.onChangeRowPerPage(newPerPage);
  };

  return (
    <TablePagination
      classes={{
        toolbar: classes.paginatorToolbar,
        actions: classes.paginatorActions,
        selectRoot: classes.paginatorSelectRoot
      }}
      rowsPerPageOptions={props.perPageList}
      component="div"
      count={props.rowsCount}
      rowsPerPage={props.perPage}
      page={props.page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
