import { RootState } from 'app/rootReducer';
import { PrivateRoute } from 'components';
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteName } from 'route';

import { Role } from './auth';

const DecisionMatrix = lazy(
  () => import('./customer/decision-matrix/DecisionMatrix')
);
const AdminClaimsDetails = lazy(
  () => import('./admin/claim-details/claim-details/AdminClaimsDetails')
);
const ClaimDetails = lazy(
  () => import('./customer/claims/claim-details/ClaimDetails')
);
const AdminClaims = lazy(() => import('./admin/claims/list/AdminClaims'));
const AdminHome = lazy(() => import('./admin/home/AdminHome'));
const DecisionMatrixEditor = lazy(
  () => import('./admin/decision-matrix-editor')
);
const SignIn = lazy(() => import('./sign-in/SignIn'));
const ClaimForm = lazy(() => import('./customer/claim-form/ClaimForm'));
const Profile = lazy(() => import('./customer/profile/Profile'));
const Account = lazy(() => import('./account/Account'));
const PrivacyPolicy = lazy(() => import('./privacy-policy/PrivacyPolicy'));
const ConfirmYourEmail = lazy(
  () => import('./email-confirmation/confirm-your-email/ConfirmYourEmail')
);
const EmailSent = lazy(
  () => import('./email-confirmation/email-sent/EmailSent')
);
const AdminQuestionnaires = lazy(
  () => import('./admin/questionnaires/AdminQuestionnaires')
);
const AdminQuestionnaireDetails = lazy(
  () =>
    import(
      './admin/questionnaires/questionnaire-details/AdminQuestionnaireDetails'
    )
);
const AdminQuestionnaireNew = lazy(
  () =>
    import(
      './admin/questionnaires/questionnaire-details/AdminQuestionnaireDetails'
    )
);

const RedirectToAdmin = () => <Redirect to={RouteName.AdminHome} />;

const solveRedirect = (role?: Role) => {
  switch (role) {
    case Role.Admin:
      return RedirectToAdmin;
    default:
      return DecisionMatrix;
  }
};

const solveDetailsComponent = (role?: Role) => {
  switch (role) {
    case Role.Admin:
      return AdminClaimsDetails;
    case Role.Customer:
      return ClaimDetails;
    default:
      return SignIn;
  }
};

export const AppContent: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  return (
    <>
      <Switch>
        <Route exact path={'/'} component={solveRedirect(user?.role)} />
        <Route exact path={RouteName.SignIn} component={SignIn} />
        <Route
          exact
          path={RouteName.ConfirmYourEmail}
          component={ConfirmYourEmail}
        />
        <Route exact path={RouteName.EmailSent} component={EmailSent} />
        <Route
          exact
          path={[RouteName.PrivacyPolicy]}
          component={PrivacyPolicy}
        />
        <PrivateRoute
          roles={[Role.Customer]}
          path={RouteName.Account}
          component={Account}
        />
        <PrivateRoute
          roles={[Role.Customer, Role.Admin]}
          path={RouteName.ClaimDetails}
          component={solveDetailsComponent(user?.role)}
        />
        <PrivateRoute
          exact
          roles={[Role.Admin]}
          path={RouteName.AdminHome}
          component={AdminHome}
        />
        <PrivateRoute
          roles={[Role.Admin]}
          path={RouteName.AdminDecisionMatrix}
          component={DecisionMatrixEditor}
        />
        <PrivateRoute
          roles={[Role.Admin]}
          path={RouteName.Claims}
          component={AdminClaims}
        />
        <PrivateRoute
          exact
          roles={[Role.Admin]}
          path={RouteName.Questionnaires}
          component={AdminQuestionnaires}
        />
        <PrivateRoute
          roles={[Role.Admin]}
          path={RouteName.QuestionnaireDetails}
          component={AdminQuestionnaireDetails}
        />
        <PrivateRoute
          roles={[Role.Admin]}
          path={RouteName.QuestionnaireNew}
          component={AdminQuestionnaireNew}
        />
        <PrivateRoute
          roles={[Role.Customer]}
          path={RouteName.ClaimForm}
          component={ClaimForm}
        />
        <PrivateRoute
          roles={[Role.Customer]}
          path={RouteName.Profile}
          component={Profile}
        />
      </Switch>
    </>
  );
};
