import { Document } from './document';

export enum MatrixType {
  Claims = 'claims', // should be equal to https://dev.azure.com/it-assistant/it-assistant/_git/it-assistant/commit/2ed2ad6056ba0a16acdff1d09911e4f8af97db26/?_a=contents&path=%252Ffunctions/src/seed/data/ru/claimMatrix.json&line=2
  BusinessTasks = 'businessTasks' // same as ^
}

export interface DecisionMatrixNode extends Document {
  name: string;
  order: number;
  parent?: string;
}

export interface DecisionMatrixLeaf extends Document {
  name: string;
  node: string;
  order: number;
  active?: boolean;
  googleFormId?: string;
  qFormId?: string;
  claimsMatrixLeaves?: string[];
  htmlDesc?: string;
}

export interface DecisionMatrix extends Document {
  name: string;
  order: number;
  showHtmlDesc?: boolean;
  showBusinessTasksRelations?: boolean;
}
