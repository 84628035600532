import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  intro: {
    margin: 0,
    padding: '59px 0 49px',
    textAlign: 'center',

    '& span': {
      color: theme.palette.secondary.main
    }
  }
}));
