import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  createStyles,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';

export interface NoDataBodyProps {
  height: number;
  colSpan: number;
}

export const useNoDataBodyStyles = makeStyles((theme: Theme) =>
  createStyles({
    noData: {
      textAlign: 'center'
    }
  })
);

export const NoDataBody: React.FC<NoDataBodyProps> = ({ height, colSpan }) => {
  const { t } = useTranslation();
  const classes = useNoDataBodyStyles();

  return (
    <TableBody>
      <TableRow style={{ height }}>
        <TableCell colSpan={colSpan}>
          <Typography component="h2" className={classes.noData}>
            {t('No data to display')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
