import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CheckboxGroup, CheckboxGroupProps } from 'components';

import { FormInputProps } from './types';

const FormCheckboxGroup: React.FC<
  FormInputProps & CheckboxGroupProps
> = React.memo(({ name, ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <CheckboxGroup {...rest} onChange={onChange} value={value} />
      )}
    />
  );
});

FormCheckboxGroup.displayName = 'FormCheckboxGroup';
export { FormCheckboxGroup };
