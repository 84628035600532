import { replace, toPairs } from 'lodash';

// Add in alphabetical order, please
enum RouteName {
  Account = '/account',
  AdminDecisionMatrix = '/admin/decision-matrix',
  AdminHome = '/admin',
  ClaimDetails = '/claim-details/:claimId',
  ClaimForm = '/claim-form/:leafId?',
  Claims = '/claims',
  Home = '/',
  PrivacyPolicy = '/privacy',
  Profile = '/profile',
  SignIn = '/sign-in',
  ConfirmYourEmail = '/confirm-your-email',
  EmailSent = '/email-sent',
  Questionnaires = '/admin/questionnaires',
  QuestionnaireDetails = '/admin/questionnaires/:questionnaireId',
  QuestionnaireNew = '/admin/questionnaires/new'
}

const buildUrl = (
  route: RouteName,
  params: Record<string, any>,
  queryParams: Record<string, any> = []
) =>
  appendQueryParams(
    toPairs(params).reduce(
      (route, [key, value]) =>
        replace(
          route,
          new RegExp(`/:(${key})[?]?(/|$)`, 'g'),
          value ? `/${value}$2` : ''
        ),
      route as string
    ),
    queryParams
  );

const appendQueryParams = (route: string, params: Record<string, any>) =>
  toPairs(params)
    .filter(([_, value]) => value)
    .reduce(
      (route, [key, value], index) =>
        route.concat(index === 0 ? '?' : '&', `${key}=${value}`),
      route
    );

export interface ClaimDetailsParams {
  claimId: string;
}
export interface QuestionnaireDetailsParams {
  questionnaireId: string;
}
export { RouteName, buildUrl };
