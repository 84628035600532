import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Typography } from '@material-ui/core';

export interface SpinnerProps {
  size?: number;
  isLoading?: boolean;
  emptyText?: string;
}

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flex: 1
  }
});

export const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps) => {
  const { wrapper } = useStyles();
  const { size, isLoading, emptyText } = props;
  if (isLoading) {
    return (
      <div className={wrapper}>
        <CircularProgress size={size ?? 24} />
      </div>
    );
  } else if (emptyText) {
    return (
      <div className={wrapper}>
        <Typography variant="h5">{emptyText}</Typography>
      </div>
    );
  } else {
    return <></>;
  }
};
