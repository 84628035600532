import * as React from 'react';

import { debounce } from 'lodash';

export function useDebounce<T extends (...args: any[]) => any>(
  fn: T,
  wait: number
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => debounce(fn, wait), [wait]);
}
