import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';
import { RouteName } from 'route';

import { RootState } from 'app/rootReducer';
import { Role } from 'features/auth/authSlice';

export interface PrivateRouteProps extends RouteProps {
  roles: Role[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  roles,
  ...rest
}) => {
  const { user, isLoading } = useSelector((state: RootState) => state.auth);

  if (!Component) return null;

  if (isLoading) return <LinearProgress />;

  return (
    <Route
      {...rest}
      render={props => {
        if (user) {
          if (!user.emailVerified) {
            return (
              <Redirect
                to={{
                  pathname: RouteName.ConfirmYourEmail,
                  state: { from: props.location }
                }}
              />
            );
          } else if (roles.includes(user?.role as Role)) {
            return <Component {...props} />;
          }
        }
        return (
          <Redirect
            to={{
              pathname: RouteName.SignIn,
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};
