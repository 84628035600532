import { AssistantType, getAssistantTitle } from 'constants/assistant-type';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouteName } from 'route';
import './Footer.scss';

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="app-footer">
      {process.env.REACT_APP_ASSISTANT_TYPE === AssistantType.ReadyAstra ? (
        t('Service provided by IAssistant')
      ) : (
        <>
          <p>
            &#169; {getAssistantTitle()} Assistant. {new Date().getFullYear()}.
            All Rights Reserved.
          </p>
          <p>
            <Link to={RouteName.PrivacyPolicy}>Privacy Policy</Link>.
          </p>
        </>
      )}
    </div>
  );
};
