import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:nth-child(odd)': {
      background: 'rgba(0, 0, 0, 0.02)'
    }
  },
  activeListItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
});
