import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import firebase from 'firebase/app';
import { RouteName } from 'route';
import { loadScript } from 'utils/load-script';

import { useStyles } from './LocalizedAuthForm.styles';

declare global {
  interface Window {
    firebaseui: any;
    firebase: any;
  }
}

const FIREBASEUI_VERSION = '4.7.1';

const firebaseui_src = (lang: string) =>
  `https://www.gstatic.com/firebasejs/ui/${FIREBASEUI_VERSION}/firebase-ui-auth__${lang}.js`;
const FIREBASEUI_CONTAINER_ID = 'firebaseui_container';

export interface LocalizedAuthFormProps {
  auth: any;
  config: any;
}

const tryDeleteApp = async () => {
  try {
    const app = (window.firebase as any).app('[DEFAULT]-firebaseui-temp');

    if (app && !(app as any).isDeleted_) {
      await app.delete();

      const container = document.getElementById(FIREBASEUI_CONTAINER_ID);
      if (container) {
        container.innerHTML = '';
      }
    }
  } catch {}
};

const startApp = async (auth: any, config: any) => {
  const ui =
    window.firebaseui.auth.AuthUI.getInstance() ??
    new window.firebaseui.auth.AuthUI(auth);

  if (ui) ui.start(`#${FIREBASEUI_CONTAINER_ID}`, config);
};

const setLang = async (lang: string, auth: any, config: any) => {
  await loadScript(firebaseui_src(lang), 'firebase-ui-auth');
  await tryDeleteApp();
  await startApp(auth, config);
};

export const LocalizedAuthForm: React.FC<LocalizedAuthFormProps> = ({
  auth,
  config
}) => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const onLangChanged = async (lang: string) => {
    await setLang(lang, auth, config);
  };

  useEffect(() => {
    window.firebase = firebase;
    setLang(i18n.language, auth, config);

    i18n.on('languageChanged', onLangChanged);

    return () => {
      i18n.off('languageChanged', onLangChanged);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <link
        type="text/css"
        rel="stylesheet"
        href={`https://www.gstatic.com/firebasejs/ui/${FIREBASEUI_VERSION}/firebase-ui-auth.css`}
      />
      <div id={FIREBASEUI_CONTAINER_ID} />
      <p className="firebaseui-tos firebaseui-tospp-full-message">
        {t('By continuing, you are indicating that you accept our')}
        <br />
        <Link
          to={RouteName.PrivacyPolicy}
          className="firebaseui-link firebaseui-pp-link"
        >
          {t('Privacy Policy')}
        </Link>
        .
      </p>
    </div>
  );
};
