import React from 'react';
import { useSelector } from 'react-redux';

import { AppBar as MatAppBar, Toolbar } from '@material-ui/core';

import { RootState } from 'app/rootReducer';
import {
  FeedbackDialog,
  LanguageSwitch,
  ProfileMenu,
  SignInButton,
  Spacer
} from 'components';

import { useAppBarStyles } from './AppBar.styles';
import { AppLogo } from './AppLogo';

export interface AppBarProps {
  signOut: () => void;
}

export const AppBar: React.FC<AppBarProps> = ({ signOut }) => {
  const classes = useAppBarStyles();
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <MatAppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.appBarToolbar}>
        <AppLogo />
        <Spacer />
        <LanguageSwitch />
        <FeedbackDialog />
        {!user ? (
          <SignInButton />
        ) : (
          <ProfileMenu user={user} signOut={signOut} />
        )}
      </Toolbar>
    </MatAppBar>
  );
};
