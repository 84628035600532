import { DecisionMatrixNode } from 'documents';

export const buildTree = (
  roots: DecisionMatrixNode[],
  selectedIds: string[],
  nodes: DecisionMatrixNode[]
) => {
  if (!roots.length) return [];

  const selectedNode = roots.find(x => selectedIds.includes(x.id!));
  const children: DecisionMatrixNode[][] = selectedNode
    ? buildTree(
        nodes.filter(x => x.parent === selectedNode.id),
        selectedIds,
        nodes
      )
    : [];

  return children.length
    ? [roots, ...children]
    : [roots.sort((a, b) => a.order - b.order)];
};

export const hasIntersection = <T>(arr1: T[], arr2: T[]) =>
  arr1.some(x => arr2.find(y => y === x));
