import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';
import { QFormSection } from 'documents';

import { useStyles } from './Sections.styles';

export interface SectionItemProps {
  value?: QFormSection;
  onChange?: (value: QFormSection) => void;
  active?: boolean;
  editOnClick?: boolean;
}

export const SectionItem: React.FC<SectionItemProps> = ({
  value,
  onChange,
  active,
  editOnClick
}: SectionItemProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {active && <div className={classes.activeMarker} />}

      <TextField
        className={classes.sectionItemInput}
        value={value?.title ?? ''}
        placeholder={t('Name of section')}
        onChange={e => onChange?.({ ...value, title: e.target.value })}
        InputProps={{ disableUnderline: editOnClick && !active }}
      />
    </div>
  );
};
