import { useEffect, useRef } from 'react';

export default function useBeforeUnload(
  callbackFn: (e: BeforeUnloadEvent) => string | undefined
) {
  const cbRef = useRef(callbackFn);

  useEffect(() => {
    cbRef.current = callbackFn;
  }, [callbackFn]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => cbRef.current?.(e);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);
}
