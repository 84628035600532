import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { RadioGroup, RadioGroupProps } from 'components';

import { FormInputProps } from './types';

const FormRadioGroup: React.FC<FormInputProps & RadioGroupProps> = React.memo(
  ({ name, ...rest }) => {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <RadioGroup {...rest} onChange={onChange} value={value} />
        )}
      />
    );
  }
);

FormRadioGroup.displayName = 'FormRadioGroup';
export { FormRadioGroup };
