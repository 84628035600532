import React from 'react';

import { Toolbar, Typography } from '@material-ui/core';

import { Spacer } from 'components';

import { EnhancedTableToolbarProps } from './';
import { TableFilter } from './filter';

export const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
  tableName,
  extra,
  filters,
  initialFiltersValue,
  onFilterChange
}) => {
  return (
    <Toolbar>
      <Typography variant="h6" id="tableTitle" component="div">
        {tableName.toUpperCase()}
      </Typography>
      <Spacer />
      {extra ?? null}
      {filters?.length && (
        <TableFilter
          filters={filters}
          initialFiltersValue={initialFiltersValue}
          onFilterChange={onFilterChange}
        />
      )}
    </Toolbar>
  );
};
