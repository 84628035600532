import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';

import { useStyles } from './Wizard.styles';

export interface WizardProps {
  count: number;
  getId: (step: number) => string;
  onFinish: () => void;
  canSubmit?: boolean;
}

export const WizardContext = React.createContext('');

export const Wizard: React.FC<WizardProps> = ({
  count,
  getId,
  onFinish,
  canSubmit,
  children
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const { t } = useTranslation();

  const isLastStep = activeStep === count - 1;

  const handleBack = () => setActiveStep(prev => prev - 1);
  const handleNext = () => {
    if (isLastStep) {
      onFinish?.();
      return;
    }
    setActiveStep(prev => prev + 1);
  };

  return (
    <article className={classes.wizard}>
      <WizardContext.Provider value={getId(activeStep)}>
        {children}
      </WizardContext.Provider>
      <div className={classes.buttonContainer}>
        {count > 1 && (
          <Button
            variant="contained"
            color="default"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            {t('Back')}
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          disabled={isLastStep && !canSubmit}
          onClick={handleNext}
        >
          {isLastStep ? t('Submit') : t('Next')}
        </Button>
      </div>
    </article>
  );
};
