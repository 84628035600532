import React from 'react';

import { TextField } from '@material-ui/core';

import { Filter } from './Filter.types';

export interface TextFilterControlProps {
  id: string;
  filter: Filter;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const TextFilterControl: React.FC<TextFilterControlProps> = ({
  id,
  filter,
  onChange
}) => <TextField id={id} label={filter.label} onChange={onChange} />;
