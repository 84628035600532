import { Document } from 'documents';
import { Moment } from 'moment';

export interface Specification extends Document {
  question: string;
  answer: string;
  order: number;
}

export interface ClaimCustomer {
  fullName: string;
  email: string;
  organizationName: string;
  phone?: string;
}

export interface Claim extends Document {
  number: number;
  name: string;
  vendor?: string;
  status: ClaimStatus;
  customerId: string;
  customer?: ClaimCustomer;
  sendingDate?: Moment;
  seenBy?: string[]; // ids of users who have read the claim already
  archivedFor?: string[]; // ids of users who marked the claim as archived
}

export enum ClaimStatus {
  InDevelopment,
  ReceivedResponse,
  Approved,
  Correction,
  Completed
}

export enum ClaimMessageSender {
  Admin,
  Customer
}

export interface ClaimMessage extends Document {
  message: string;
  date: Moment;
  sender: ClaimMessageSender | string;
  files?: ClaimMessageFile[];
}

export interface ClaimMessageFile {
  fileName: string;
  downloadUrl: string;
}
