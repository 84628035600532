import { Document } from 'documents';
import debounce from 'lodash/debounce';

import {
  createQuery,
  db,
  getDataConverter,
  OrderByCondition,
  WhereCondition
} from './';

export interface CollectionQuery {
  where?: WhereCondition | WhereCondition[];
  orderBy?: OrderByCondition;
}

export interface DocQuery extends CollectionQuery {
  id?: string;
}

export interface Repo<T extends Document> {
  getList: (query?: CollectionQuery) => Promise<T[]>;
  find: (id: string) => Promise<T | undefined>;
  first: (query?: CollectionQuery) => Promise<T | undefined>;
  add: (item: T) => Promise<string | undefined>;
  set: (item: T) => Promise<void>;
  setWithMerge: (item: T) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export function createRepo<T extends Document>(
  collectionPath: string | string[],
  delay?: number
): Repo<T> {
  const dataConverter = getDataConverter<T>();

  const path = Array.isArray(collectionPath)
    ? collectionPath.join('/')
    : collectionPath;

  const raw = {
    add: async (item: T) => {
      const docRef = await db
        .collection(path)
        .withConverter(dataConverter)
        .add(item);
      return docRef?.id;
    },
    set: (item: T) =>
      db.collection(path).doc(item.id).withConverter(dataConverter).set(item),
    setWithMerge: (item: T) =>
      db
        .collection(path)
        .doc(item.id)
        .withConverter(dataConverter)
        .set(item, { merge: true }),
    delete: (id: string) => db.collection(path).doc(id).delete()
  };

  const debounced = {
    add: debounce(raw.add, delay),
    set: debounce(raw.set, delay),
    delete: debounce(raw.delete, delay),
    setWithMerge: debounce(raw.setWithMerge, delay)
  };

  return {
    getList: async (query?: CollectionQuery) => {
      const snapshot = await createQuery<T>(
        path,
        db,
        dataConverter,
        query?.where,
        query?.orderBy
      ).get();
      const items: T[] = [];
      snapshot.forEach(doc => items.push(doc.data()));

      return items;
    },
    find: async id => {
      const docSnapshot = await db
        .collection(path)
        .doc(id)
        .withConverter(dataConverter)
        .get();
      return docSnapshot.data();
    },
    first: async query => {
      let doc: T | undefined;
      const snapshot = await createQuery<T>(
        path,
        db,
        dataConverter,
        query?.where,
        query?.orderBy
      )
        .limit(1)
        .get();
      if (snapshot.docs.length > 0) {
        doc = snapshot.docs[0].data();
      }

      return doc;
    },
    add: async (item: T) => (delay ? debounced.add(item) : raw.add(item)),
    set: async (item: T) => (delay ? debounced.set(item) : raw.set(item)),
    delete: async (id: string) =>
      delay ? debounced.delete(id) : raw.delete(id),
    setWithMerge: async (item: T) =>
      delay ? debounced.setWithMerge(item) : raw.setWithMerge(item)
  };
}
